<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img src="/vitatraq.svg" alt="Vitatraq logo" height="65" />
      </v-col>
      <v-col cols="12">
        <div data-custom-class="body">
          <div>
            <strong
              ><span style="font-size: 26px"
                ><span data-custom-class="title">PRIVACY POLICY</span></span
              ></strong
            >
          </div>
          <div>
            <br />
          </div>
          <div>
            <span style="color: rgb(127, 127, 127)"
              ><strong
                ><span style="font-size: 15px"
                  ><span data-custom-class="subtitle"
                    >Last updated October 08, 2024</span
                  ></span
                ></strong
              ></span
            >
          </div>
          <div>
            <br />
          </div>
          <div>
            <br />
          </div>
          <div>
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                ><span data-custom-class="body_text"
                  >This Privacy Notice for Vitatraq LLC (doing business as
                  Vitatraq) ("<strong>we</strong>," "<strong>us</strong>," or
                  "<strong>our</strong>"</span
                ><span data-custom-class="body_text"
                  >), describes how and why we might access, collect, store,
                  use, and/or share ("<strong>process</strong>") your personal
                  information when you use our services
                  ("<strong>Services</strong>"), including when you:</span
                ></span
              ></span
            >
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    >Visit our website at
                    <span style="color: rgb(0, 58, 250)"
                      ><a
                        href="https://www.vitatraq.com"
                        target="_blank"
                        data-custom-class="link"
                        >https://www.vitatraq.com</a
                      ></span
                    ><span style="font-size: 15px"
                      ><span style="color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><span style="font-size: 15px"
                            ><span style="color: rgb(89, 89, 89)"
                              >, or any website of ours that links to this
                              Privacy Notice</span
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    >Download and use our mobile application (Vitatraq)<span
                      style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><span style="font-size: 15px"
                            ><span style="color: rgb(89, 89, 89)"
                              ><span data-custom-class="body_text"
                                ><span style="font-size: 15px"
                                  ><span style="color: rgb(89, 89, 89)"
                                    >,</span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                  <span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span style="color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><span style="font-size: 15px"
                            ><span style="color: rgb(89, 89, 89)"
                              >or any other application of ours that links to
                              this Privacy Notice</span
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px"
                >Use Vitatraq. Our solutions address the core challenges facing
                modern practices: efficiency, rising costs, and the need to
                improve the experience for both providers and patients. Our
                integrated solutions work seamlessly drawing from multiple data
                sources for maximum effectiveness Our platform prioritizes
                security and privacy with industry-leading technologies. AI,
                automation, and analytics optimize your workflows for superior
                patient care</span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    >Engage with us in other related ways, including any sales,
                    marketing, or events</span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(127, 127, 127)"
                ><span data-custom-class="body_text"
                  ><strong>Questions or concerns?&nbsp;</strong>Reading this
                  Privacy Notice will help you understand your privacy rights
                  and choices. We are responsible for making decisions about how
                  your personal information is processed. If you do not agree
                  with our policies and practices, please do not use our
                  Services. If you still have any questions or concerns, please
                  contact us at support@vitatraq.com.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <strong
              ><span style="font-size: 15px"
                ><span data-custom-class="heading_1"
                  >SUMMARY OF KEY POINTS</span
                ></span
              ></strong
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  ><em
                    >This summary provides key points from our Privacy Notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our&nbsp;</em
                  ></strong
                ></span
              ></span
            ><a data-custom-class="link" href="#toc"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><strong><em>table of contents</em></strong></span
                ></span
              ></a
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  ><em
                    >&nbsp;below to find the section you are looking for.</em
                  ></strong
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>What personal information do we process?</strong> When
                you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with us and
                the Services, the choices you make, and the products and
                features you use. Learn more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#personalinfo"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >personal information you disclose to us</span
                ></span
              ></a
            ><span data-custom-class="body_text">.</span>
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  >Do we process any sensitive personal
                  information?&nbsp;</strong
                >Some of the information may be considered "special" or
                "sensitive" in certain jurisdictions, for example your racial or
                ethnic origins, sexual orientation, and religious beliefs. We
                may process sensitive personal information when necessary with
                your consent or as otherwise permitted by applicable law. Learn
                more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#sensitiveinfo"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >sensitive information we process</span
                ></span
              ></a
            ><span data-custom-class="body_text">.</span>
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  >Do we collect any information from third parties?</strong
                >
                We do not collect any information from third parties.</span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>How do we process your information?</strong> We process
                your information to provide, improve, and administer our
                Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Learn more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#infouse"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >how we process your information</span
                ></span
              ></a
            ><span data-custom-class="body_text">.</span>
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  >In what situations and with which parties do we share
                  personal information?</strong
                >
                We may share information in specific situations and with
                specific third parties. Learn more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#whoshare"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >when and with whom we share your personal information</span
                ></span
              ></a
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">.</span></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>How do we keep your information safe?</strong> We have
                adequate organizational and technical processes and procedures
                in place to protect your personal information. However, no
                electronic transmission over the internet or information storage
                technology can be guaranteed to be 100% secure, so we cannot
                promise or guarantee that hackers, cybercriminals, or other
                unauthorized third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information. Learn more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#infosafe"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >how we keep your information safe</span
                ></span
              ></a
            ><span data-custom-class="body_text">.</span>
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>What are your rights?</strong> Depending on where you
                are located geographically, the applicable privacy law may mean
                you have certain rights regarding your personal information.
                Learn more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#privacyrights"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >your privacy rights</span
                ></span
              ></a
            ><span data-custom-class="body_text">.</span>
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>How do you exercise your rights?</strong> The easiest
                way to exercise your rights is by visiting
                <span style="color: rgb(0, 58, 250)"
                  ><a
                    href="https://www.vitatraq.com/contact"
                    target="_blank"
                    data-custom-class="link"
                    >https://www.vitatraq.com/contact</a
                  ></span
                >, or by contacting us. We will consider and act upon any
                request in accordance with applicable data protection
                laws.</span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                >Want to learn more about what we do with any information we
                collect?&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#toc"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >Review the Privacy Notice in full</span
                ></span
              ></a
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">.</span></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div id="toc" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(0, 0, 0)"
                  ><strong
                    ><span data-custom-class="heading_1"
                      >TABLE OF CONTENTS</span
                    ></strong
                  ></span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#infocollect"
                ><span style="color: rgb(0, 58, 250)"
                  >1. WHAT INFORMATION DO WE COLLECT?</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#infouse"
                ><span style="color: rgb(0, 58, 250)"
                  >2. HOW DO WE PROCESS YOUR INFORMATION?</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#legalbases"
                ><span style="color: rgb(0, 58, 250)"
                  >3.
                  <span style="font-size: 15px"
                    ><span style="color: rgb(0, 58, 250)"
                      >WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                      INFORMATION?</span
                    ></span
                  ></span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(0, 58, 250)"
                ><a data-custom-class="link" href="#whoshare"
                  >4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                  INFORMATION?</a
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#cookies"
                ><span style="color: rgb(0, 58, 250)"
                  >5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <a data-custom-class="link" href="#ai"
              ><span style="color: rgb (0, 58, 250)"
                >6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</span
              ></a
            >
          </div>
          <div style="line-height: 1.5"></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#sociallogins"
                ><span style="color: rgb(0, 58, 250)"
                  ><span style="color: rgb(0, 58, 250)"
                    ><span style="color: rgb(0, 58, 250)"
                      >7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span
                    ></span
                  ></span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#inforetain"
                ><span style="color: rgb(0, 58, 250)"
                  >8. HOW LONG DO WE KEEP YOUR INFORMATION?</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#infosafe"
                ><span style="color: rgb(0, 58, 250)"
                  >9. HOW DO WE KEEP YOUR INFORMATION SAFE?</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#infominors"
                ><span style="color: rgb(0, 58, 250)"
                  >10. DO WE COLLECT INFORMATION FROM MINORS?</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(0, 58, 250)"
                ><a data-custom-class="link" href="#privacyrights"
                  >11. WHAT ARE YOUR PRIVACY RIGHTS?</a
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#DNT"
                ><span style="color: rgb(0, 58, 250)"
                  >12. CONTROLS FOR DO-NOT-TRACK FEATURES</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#uslaws"
                ><span style="color: rgb(0, 58, 250)"
                  >13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
                  RIGHTS?</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5"></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#policyupdates"
                ><span style="color: rgb(0, 58, 250)"
                  >14. DO WE MAKE UPDATES TO THIS NOTICE?</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <a data-custom-class="link" href="#contact"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                >15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span
              ></a
            >
          </div>
          <div style="line-height: 1.5">
            <a data-custom-class="link" href="#request"
              ><span style="color: rgb(0, 58, 250)"
                >16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?</span
              ></a
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div id="infocollect" style="line-height: 1.5">
            <span style="color: rgb(0, 0, 0)"
              ><span style="color: rgb(0, 0, 0); font-size: 15px"
                ><span style="font-size: 15px; color: rgb(0, 0, 0)"
                  ><span style="font-size: 15px; color: rgb(0, 0, 0)"
                    ><span id="control" style="color: rgb(0, 0, 0)"
                      ><strong
                        ><span data-custom-class="heading_1"
                          >1. WHAT INFORMATION DO WE COLLECT?</span
                        ></strong
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div id="personalinfo" style="line-height: 1.5">
            <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
              ><span style="font-size: 15px"
                ><strong>Personal information you disclose to us</strong></span
              ></span
            >
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style="line-height: 1.5">
              <span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong><em>In Short:</em></strong></span
                        ></span
                      ></span
                    ></span
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong><em>&nbsp;</em></strong
                          ><em
                            >We collect personal information that you provide to
                            us.</em
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </div>
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >We collect personal information that you voluntarily provide
                  to us when you register on the Services,&nbsp;</span
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"
                    >express an interest in obtaining information about us or
                    our products and Services, when you participate in
                    activities on the Services, or otherwise when you contact
                    us.</span
                  ></span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5"></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><strong>Personal Information Provided by You.</strong> The
                  personal information that we collect depends on the context of
                  your interactions with us and the Services, the choices you
                  make, and the products and features you use. The personal
                  information we collect may include the following:</span
                ></span
              ></span
            >
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text">names</span></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        >phone numbers</span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        >email addresses</span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        >mailing addresses</span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        >usernames</span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        >passwords</span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        >contact preferences</span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        >contact or authentication data</span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        >billing addresses</span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        >debit/credit card numbers</span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <div id="sensitiveinfo" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>Sensitive Information.</strong> When necessary, with
                your consent or as otherwise permitted by applicable law, we
                process the following categories of sensitive information:</span
              ></span
            >
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px"
                ><span data-custom-class="body_text">health data</span></span
              >
            </li>
          </ul>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px"
                ><span data-custom-class="body_text">biometric data</span></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><strong>Social Media Login Data.&nbsp;</strong>We may provide
                  you with the option to register with us using your existing
                  social media account details, like your Facebook, X, or other
                  social media account. If you choose to register in this way,
                  we will collect certain profile information about you from the
                  social media provider, as described in the section called
                  "<span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><span style="color: rgb(0, 58, 250)"
                          ><a data-custom-class="link" href="#sociallogins"
                            >HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a
                          ></span
                        ></span
                      ></span
                    ></span
                  >" below.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5"></div>
          <div style="line-height: 1.5">
            <span data-custom-class="body_text"
              ><span style="font-size: 15px"
                ><strong>Application Data.</strong> If you use our
                application(s), we also may collect the following information if
                you choose to provide us with access or permission:</span
              ></span
            >
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span data-custom-class="body_text"
                ><span style="font-size: 15px"
                  ><em>Geolocation Information.</em> We may request access or
                  permission to track location-based information from your
                  mobile device, either continuously or while you are using our
                  mobile application(s), to provide certain location-based
                  services. If you wish to change our access or permissions, you
                  may do so in your device's settings.</span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px"
                ><span data-custom-class="body_text"
                  ><em>Mobile Device Access.</em> We may request access or
                  permission to certain features from your mobile device,
                  including your mobile device's bluetooth, camera, microphone,
                  reminders, sensors, storage, sms messages, social media
                  accounts, calendar, contacts, and other features. If you wish
                  to change our access or permissions, you may do so in your
                  device's settings.</span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px"
                ><span data-custom-class="body_text"
                  ><em>Mobile Device Data.</em> We automatically collect device
                  information (such as your mobile device ID, model, and
                  manufacturer), operating system, version information and
                  system configuration information, device and application
                  identification numbers, browser type and version, hardware
                  model Internet service provider and/or mobile carrier, and
                  Internet Protocol (IP) address (or proxy server). If you are
                  using our application(s), we may also collect information
                  about the phone network associated with your mobile device,
                  your mobile device’s operating system or platform, the type of
                  mobile device you use, your mobile device’s unique device ID,
                  and information about the features of our application(s) you
                  accessed.</span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px"
                ><span data-custom-class="body_text"
                  ><em>Push Notifications.</em> We may request to send you push
                  notifications regarding your account or certain features of
                  the application(s). If you wish to opt out from receiving
                  these types of communications, you may turn them off in your
                  device's settings.</span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                >This information is primarily needed to maintain the security
                and operation of our application(s), for troubleshooting, and
                for our internal analytics and reporting purposes.</span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5"></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5"></div>
          <div style="line-height: 1.5">
            <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
              ><span style="font-size: 15px"
                ><strong>Information automatically collected</strong></span
              ></span
            >
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style="line-height: 1.5">
              <span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong><em>In Short:</em></strong></span
                        ></span
                      ></span
                    ></span
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong><em>&nbsp;</em></strong
                          ><em
                            >Some information — such as your Internet Protocol
                            (IP) address and/or browser and device
                            characteristics — is collected automatically when
                            you visit our Services.</em
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </div>
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >We automatically collect certain information when you visit,
                  use, or navigate the Services. This information does not
                  reveal your specific identity (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our Services, and other technical information. This
                  information is primarily needed to maintain the security and
                  operation of our Services, and for our internal analytics and
                  reporting purposes.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5"></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >Like many businesses, we also collect information through
                  cookies and similar technologies.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5"></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >The information we collect includes:</span
                ></span
              ></span
            >
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><em>Log and Usage Data.</em> Log and usage data is
                    service-related, diagnostic, usage, and performance
                    information our servers automatically collect when you
                    access or use our Services and which we record in log files.
                    Depending on how you interact with us, this log data may
                    include your IP address, device information, browser type,
                    and settings and information about your activity in the
                    Services<span style="font-size: 15px">&nbsp;</span>(such as
                    the date/time stamps associated with your usage, pages and
                    files viewed, searches, and other actions you take such as
                    which features you use), device event information (such as
                    system activity, error reports (sometimes called "crash
                    dumps"), and hardware settings).</span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><em>Device Data.</em> We collect device data such as
                    information about your computer, phone, tablet, or other
                    device you use to access the Services. Depending on the
                    device used, this device data may include information such
                    as your IP address (or proxy server), device and application
                    identification numbers, location, browser type, hardware
                    model, Internet service provider and/or mobile carrier,
                    operating system, and system configuration
                    information.</span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><em>Location Data.</em> We collect location data such as
                    information about your device's location, which can be
                    either precise or imprecise. How much information we collect
                    depends on the type and settings of the device you use to
                    access the Services. For example, we may use GPS and other
                    technologies to collect geolocation data that tells us your
                    current location (based on your IP address). You can opt out
                    of allowing us to collect this information either by
                    refusing access to the information or by disabling your
                    Location setting on your device. However, if you choose to
                    opt out, you may not be able to use certain aspects of the
                    Services.</span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div id="infouse" style="line-height: 1.5">
            <span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span id="control" style="color: rgb(0, 0, 0)"
                      ><strong
                        ><span data-custom-class="heading_1"
                          >2. HOW DO WE PROCESS YOUR INFORMATION?</span
                        ></strong
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
          <div>
            <div style="line-height: 1.5">
              <br />
            </div>
            <div style="line-height: 1.5">
              <span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong><em>In Short:&nbsp;</em></strong
                          ><em
                            >We process your information to provide, improve,
                            and administer our Services, communicate with you,
                            for security and fraud prevention, and to comply
                            with law. We may also process your information for
                            other purposes with your consent.</em
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </div>
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><strong
                    >We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:</strong
                  ></span
                ></span
              ></span
            >
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><strong
                      >To facilitate account creation and authentication and
                      otherwise manage user accounts.&nbsp;</strong
                    >We may process your information so you can create and log
                    in to your account, as well as keep your account in working
                    order.</span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5"></div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><strong
                      >To deliver and facilitate delivery of services to the
                      user.&nbsp;</strong
                    >We may process your information to provide you with the
                    requested service.</span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <div style="line-height: 1.5"></div>
            <ul>
              <li data-custom-class="body_text" style="line-height: 1.5">
                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><strong
                        >To respond to user inquiries/offer support to
                        users.&nbsp;</strong
                      >We may process your information to respond to your
                      inquiries and solve any potential issues you might have
                      with the requested service.</span
                    ></span
                  ></span
                >
              </li>
            </ul>
            <div style="line-height: 1.5">
              <div style="line-height: 1.5"></div>
              <ul>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong
                          >To send administrative information to
                          you.&nbsp;</strong
                        >We may process your information to send you details
                        about our products and services, changes to our terms
                        and policies, and other similar information.</span
                      ></span
                    ></span
                  >
                </li>
              </ul>
              <div style="line-height: 1.5">
                <div style="line-height: 1.5">
                  <div style="line-height: 1.5">
                    <p style="font-size: 15px; line-height: 1.5"></p>
                    <ul>
                      <li
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="font-size: 15px"
                          ><span style="color: rgb(89, 89, 89)"
                            ><span style="color: rgb(89, 89, 89)"
                              ><span data-custom-class="body_text"
                                ><strong
                                  >To enable user-to-user
                                  communications.&nbsp;</strong
                                >We may process your information if you choose
                                to use any of our offerings that allow for
                                communication with another user.</span
                              ></span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <p style="font-size: 15px; line-height: 1.5"></p>
                    <p style="font-size: 15px; line-height: 1.5"></p>
                    <ul>
                      <li
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="font-size: 15px"
                          ><span style="color: rgb(89, 89, 89)"
                            ><span style="color: rgb(89, 89, 89)"
                              ><span data-custom-class="body_text"
                                ><strong>To request feedback.&nbsp;</strong>We
                                may process your information when necessary to
                                request feedback and to contact you about your
                                use of our Services.</span
                              ></span
                            ></span
                          ></span
                        >
                      </li>
                    </ul>
                    <p style="font-size: 15px; line-height: 1.5"></p>
                    <div style="line-height: 1.5">
                      <div style="line-height: 1.5">
                        <div style="line-height: 1.5">
                          <div style="line-height: 1.5">
                            <div style="line-height: 1.5">
                              <div style="line-height: 1.5"></div>
                              <ul>
                                <li
                                  data-custom-class="body_text"
                                  style="line-height: 1.5"
                                >
                                  <span data-custom-class="body_text"
                                    ><span style="font-size: 15px"
                                      ><strong>To protect our Services.</strong>
                                      We may process your information as part of
                                      our efforts to keep our Services safe and
                                      secure, including fraud monitoring and
                                      prevention.</span
                                    ></span
                                  >
                                </li>
                              </ul>
                              <div style="line-height: 1.5">
                                <div style="line-height: 1.5">
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li
                                      data-custom-class="body_text"
                                      style="line-height: 1.5"
                                    >
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          ><strong
                                            >To evaluate and improve our
                                            Services, products, marketing, and
                                            your experience.</strong
                                          >
                                          We may process your information when
                                          we believe it is necessary to identify
                                          usage trends, determine the
                                          effectiveness of our promotional
                                          campaigns, and to evaluate and improve
                                          our Services, products, marketing, and
                                          your experience.</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li
                                      data-custom-class="body_text"
                                      style="line-height: 1.5"
                                    >
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          ><strong
                                            >To identify usage trends.</strong
                                          >
                                          We may process information about how
                                          you use our Services to better
                                          understand how they are being used so
                                          we can improve them.</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5">
                                    <div style="line-height: 1.5">
                                      <div style="line-height: 1.5">
                                        <div style="line-height: 1.5"></div>
                                        <ul>
                                          <li
                                            data-custom-class="body_text"
                                            style="line-height: 1.5"
                                          >
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                                ><strong
                                                  >To comply with our legal
                                                  obligations.</strong
                                                >
                                                We may process your information
                                                to comply with our legal
                                                obligations, respond to legal
                                                requests, and exercise,
                                                establish, or defend our legal
                                                rights.</span
                                              ></span
                                            >
                                          </li>
                                        </ul>
                                        <div style="line-height: 1.5">
                                          <div style="line-height: 1.5"></div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="legalbases"
                                            style="line-height: 1.5"
                                          >
                                            <strong
                                              ><span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="heading_1"
                                                  >3. WHAT LEGAL BASES DO WE
                                                  RELY ON TO PROCESS YOUR
                                                  INFORMATION?</span
                                                ></span
                                              ></strong
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <em
                                              ><span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    >In Short:&nbsp;</strong
                                                  >We only process your personal
                                                  information when we believe it
                                                  is necessary and we have a
                                                  valid legal reason (i.e.,
                                                  legal basis) to do so under
                                                  applicable law, like with your
                                                  consent, to comply with laws,
                                                  to provide you with services
                                                  to enter into or fulfill our
                                                  contractual obligations, to
                                                  protect your rights, or to
                                                  fulfill our legitimate
                                                  business interests.</span
                                                ></span
                                              ></em
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5"></div>
                                          <div style="line-height: 1.5">
                                            <span data-custom-class="body_text"
                                              ><span style="font-size: 15px"
                                                ><strong
                                                  ><u
                                                    ><em
                                                      >If you are located in
                                                      Canada, this section
                                                      applies to you.</em
                                                    ></u
                                                  ></strong
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5"></div>
                                          <div style="line-height: 1.5">
                                            <span data-custom-class="body_text"
                                              ><span style="font-size: 15px"
                                                >We may process your information
                                                if you have given us specific
                                                permission (i.e., express
                                                consent) to use your personal
                                                information for a specific
                                                purpose, or in situations where
                                                your permission can be inferred
                                                (i.e., implied consent). You
                                                can&nbsp;</span
                                              ></span
                                            ><a
                                              data-custom-class="link"
                                              href="#withdrawconsent"
                                              ><span
                                                data-custom-class="body_text"
                                                ><span
                                                  style="
                                                    color: rgb(0, 58, 250);
                                                    font-size: 15px;
                                                  "
                                                  >withdraw your consent</span
                                                ></span
                                              ></a
                                            ><span data-custom-class="body_text"
                                              ><span style="font-size: 15px"
                                                >&nbsp;at any time.</span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span data-custom-class="body_text"
                                              ><span style="font-size: 15px"
                                                >In some exceptional cases, we
                                                may be legally permitted under
                                                applicable law to process your
                                                information without your
                                                consent, including, for
                                                example:</span
                                              ></span
                                            >
                                          </div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >If collection is clearly in
                                                  the interests of an individual
                                                  and consent cannot be obtained
                                                  in a timely way</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >For investigations and fraud
                                                  detection and prevention</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >For business transactions
                                                  provided certain conditions
                                                  are met</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >If it is contained in a
                                                  witness statement and the
                                                  collection is necessary to
                                                  assess, process, or settle an
                                                  insurance claim</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >For identifying injured, ill,
                                                  or deceased persons and
                                                  communicating with next of
                                                  kin</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >If we have reasonable grounds
                                                  to believe an individual has
                                                  been, is, or may be victim of
                                                  financial abuse</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >If it is reasonable to expect
                                                  collection and use with
                                                  consent would compromise the
                                                  availability or the accuracy
                                                  of the information and the
                                                  collection is reasonable for
                                                  purposes related to
                                                  investigating a breach of an
                                                  agreement or a contravention
                                                  of the laws of Canada or a
                                                  province</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >If disclosure is required to
                                                  comply with a subpoena,
                                                  warrant, court order, or rules
                                                  of the court relating to the
                                                  production of records</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If it was produced by an
                                                  individual in the course of
                                                  their employment, business, or
                                                  profession and the collection
                                                  is consistent with the
                                                  purposes for which the
                                                  information was produced</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If the collection is solely
                                                  for journalistic, artistic, or
                                                  literary purposes</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If the information is
                                                  publicly available and is
                                                  specified by the
                                                  regulations</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="whoshare"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >4. WHEN AND WITH WHOM
                                                          DO WE SHARE YOUR
                                                          PERSONAL
                                                          INFORMATION?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><em>In Short:</em></strong
                                                  ><em
                                                    >&nbsp;We may share
                                                    information in specific
                                                    situations described in this
                                                    section and/or with the
                                                    following third parties.</em
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5"></div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                                >We may need to share your
                                                personal information in the
                                                following situations:</span
                                              ></span
                                            >
                                          </div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    >Business Transfers.</strong
                                                  >
                                                  We may share or transfer your
                                                  information in connection
                                                  with, or during negotiations
                                                  of, any merger, sale of
                                                  company assets, financing, or
                                                  acquisition of all or a
                                                  portion of our business to
                                                  another company.</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    >When we use Google Maps
                                                    Platform APIs.</strong
                                                  >
                                                  We may share your information
                                                  with certain Google Maps
                                                  Platform APIs (e.g., Google
                                                  Maps API, Places API).</span
                                                >
                                                <span
                                                  data-custom-class="body_text"
                                                  >We use certain Google Maps
                                                  Platform APIs to retrieve
                                                  certain information when you
                                                  make location-specific
                                                  requests. This includes:
                                                  Current location; and other
                                                  similar information. A full
                                                  list of what we use
                                                  information for can be found
                                                  in this section and in the
                                                  previous section titled
                                                  "</span
                                                ></span
                                              ><a
                                                data-custom-class="link"
                                                href="#infouse"
                                                ><span
                                                  style="
                                                    color: rgb(0, 58, 250);
                                                    font-size: 15px;
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >HOW DO WE PROCESS YOUR
                                                    INFORMATION?</span
                                                  ></span
                                                ></a
                                              ><span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  >" Google Maps uses GPS,
                                                  Wi-Fi, and cell towers to
                                                  estimate your location. GPS is
                                                  accurate to about 20 meters,
                                                  while Wi-Fi and cell towers
                                                  help improve accuracy when GPS
                                                  signals are weak, like
                                                  indoors. This data helps
                                                  Google Maps provide
                                                  directions, but it is not
                                                  always perfectly precise. We
                                                  obtain and store on your
                                                  device ("cache") your location
                                                  for six (6) months. You may
                                                  revoke your consent anytime by
                                                  contacting us at the contact
                                                  details provided at the end of
                                                  this document. The Google Maps
                                                  Platform APIs that we use
                                                  store and access cookies and
                                                  other information on your
                                                  devices. If you are a user
                                                  currently in the European
                                                  Economic Area (EU countries,
                                                  Iceland, Liechtenstein, and
                                                  Norway) or the United Kingdom,
                                                  please take a look at our
                                                  Cookie Notice.</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    >Affiliates.&nbsp;</strong
                                                  >We may share your information
                                                  with our affiliates, in which
                                                  case we will require those
                                                  affiliates to honor this
                                                  Privacy Notice. Affiliates
                                                  include our parent company and
                                                  any subsidiaries, joint
                                                  venture partners, or other
                                                  companies that we control or
                                                  that are under common control
                                                  with us.</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li
                                              data-custom-class="body_text"
                                              style="line-height: 1.5"
                                            >
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  ><strong
                                                    >Business Partners.</strong
                                                  >
                                                  We may share your information
                                                  with our business partners to
                                                  offer you certain products,
                                                  services, or promotions.</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5">
                                            <div style="line-height: 1.5">
                                              <div
                                                style="line-height: 1.5"
                                              ></div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div
                                                id="cookies"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  style="
                                                    color: rgb(127, 127, 127);
                                                  "
                                                  ><span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          id="control"
                                                          style="
                                                            color: rgb(0, 0, 0);
                                                          "
                                                          ><strong
                                                            ><span
                                                              data-custom-class="heading_1"
                                                              >5. DO WE USE
                                                              COOKIES AND OTHER
                                                              TRACKING
                                                              TECHNOLOGIES?</span
                                                            ></strong
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><strong
                                                        ><em
                                                          >In Short:</em
                                                        ></strong
                                                      ><em
                                                        >&nbsp;We may use
                                                        cookies and other
                                                        tracking technologies to
                                                        collect and store your
                                                        information.</em
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >We may use cookies and
                                                      similar tracking
                                                      technologies (like web
                                                      beacons and pixels) to
                                                      gather information when
                                                      you interact with our
                                                      Services. Some online
                                                      tracking technologies help
                                                      us maintain the security
                                                      of our Services and your
                                                      account, prevent crashes,
                                                      fix bugs, save your
                                                      preferences, and assist
                                                      with basic site
                                                      functions.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >We also permit third
                                                      parties and service
                                                      providers to use online
                                                      tracking technologies on
                                                      our Services for analytics
                                                      and advertising, including
                                                      to help manage and display
                                                      advertisements, to tailor
                                                      advertisements to your
                                                      interests, or to send
                                                      abandoned shopping cart
                                                      reminders (depending on
                                                      your communication
                                                      preferences). The third
                                                      parties and service
                                                      providers use their
                                                      technology to provide
                                                      advertising about products
                                                      and services tailored to
                                                      your interests which may
                                                      appear either on our
                                                      Services or on other
                                                      websites.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><br
                                                /></span>
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >To the extent these online
                                                    tracking technologies are
                                                    deemed to be a
                                                    "sale"/"sharing" (which
                                                    includes targeted
                                                    advertising, as defined
                                                    under the applicable laws)
                                                    under applicable US state
                                                    laws, you can opt out of
                                                    these online tracking
                                                    technologies by submitting a
                                                    request as described below
                                                    under section "</span
                                                  ></span
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><a
                                                    data-custom-class="link"
                                                    href="#uslaws"
                                                    ><span
                                                      style="
                                                        color: rgb(0, 58, 250);
                                                        font-size: 15px;
                                                      "
                                                      >DO UNITED STATES
                                                      RESIDENTS HAVE SPECIFIC
                                                      PRIVACY RIGHTS?</span
                                                    ></a
                                                  ></span
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >"</span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Specific information
                                                      about how we use such
                                                      technologies and how you
                                                      can refuse certain cookies
                                                      is set out in our Cookie
                                                      Notice<span
                                                        style="
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                          font-size: 15px;
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >.</span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><br
                                                /></span>
                                              </div>
                                              <div
                                                id="ai"
                                                style="line-height: 1.5"
                                              >
                                                <span style="font-size: 15px"
                                                  ><strong
                                                    ><span
                                                      data-custom-class="heading_1"
                                                      >6. DO WE OFFER ARTIFICIAL
                                                      INTELLIGENCE-BASED
                                                      PRODUCTS?</span
                                                    ></strong
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><br
                                                /></span>
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><strong
                                                    ><em
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >In Short:</span
                                                      ></em
                                                    ></strong
                                                  ><em
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >&nbsp;We offer products,
                                                      features, or tools powered
                                                      by artificial
                                                      intelligence, machine
                                                      learning, or similar
                                                      technologies.</span
                                                    ></em
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >As part of our Services, we
                                                    offer products, features, or
                                                    tools powered by artificial
                                                    intelligence, machine
                                                    learning, or similar
                                                    technologies (collectively,
                                                    "AI Products"). These tools
                                                    are designed to enhance your
                                                    experience and provide you
                                                    with innovative solutions.
                                                    The terms in this Privacy
                                                    Notice govern your use of
                                                    the AI Products within our
                                                    Services.</span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><strong
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Use of AI
                                                      Technologies</span
                                                    ></strong
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >We provide the AI Products
                                                    through third-party service
                                                    providers ("AI Service
                                                    Providers"), including
                                                    Amazon Web Services (AWS) AI
                                                    and OpenAI. As outlined in
                                                    this Privacy Notice, your
                                                    input, output, and personal
                                                    information will be shared
                                                    with and processed by these
                                                    AI Service Providers to
                                                    enable your use of our AI
                                                    Products for purposes
                                                    outlined in "</span
                                                  ><a
                                                    data-custom-class="link"
                                                    href="#whoshare"
                                                    ><span
                                                      style="
                                                        color: rgb(0, 58, 250);
                                                        font-size: 15px;
                                                      "
                                                      >WHEN AND WITH WHOM DO WE
                                                      SHARE YOUR PERSONAL
                                                      INFORMATION?</span
                                                    ></a
                                                  ></span
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >" You must not use the AI
                                                    Products in any way that
                                                    violates the terms or
                                                    policies of any AI Service
                                                    Provider.</span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><br
                                                /></span>
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><strong
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Our AI Products</span
                                                    ></strong
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><br
                                                /></span>
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >Our AI Products are
                                                    designed for the following
                                                    functions:</span
                                                  ></span
                                                >
                                              </div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >AI applications</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >AI bots</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >AI deployment</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >AI development</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >AI insights</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >AI translation</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Blockchain</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Image analysis</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Video analysis</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Natural language
                                                      processing</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >AI predictive
                                                      analytics</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >AI document
                                                      generation</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >IoT</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Text analysis</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >AI automation</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <div>
                                                <span style="font-size: 15px"
                                                  ><br
                                                /></span>
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><strong
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >How We Process Your Data
                                                      Using AI</span
                                                    ></strong
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><br
                                                /></span>
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >All personal information
                                                    processed using our AI
                                                    Products is handled in line
                                                    with our Privacy Notice and
                                                    our agreement with third
                                                    parties. This ensures high
                                                    security and safeguards your
                                                    personal information
                                                    throughout the process,
                                                    giving you peace of mind
                                                    about your data's
                                                    safety.</span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div
                                                id="sociallogins"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  style="
                                                    color: rgb(127, 127, 127);
                                                  "
                                                  ><span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          id="control"
                                                          style="
                                                            color: rgb(0, 0, 0);
                                                          "
                                                          ><strong
                                                            ><span
                                                              data-custom-class="heading_1"
                                                              >7. HOW DO WE
                                                              HANDLE YOUR SOCIAL
                                                              LOGINS?</span
                                                            ></strong
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><strong
                                                        ><em
                                                          >In Short:&nbsp;</em
                                                        ></strong
                                                      ><em
                                                        >If you choose to
                                                        register or log in to
                                                        our Services using a
                                                        social media account, we
                                                        may have access to
                                                        certain information
                                                        about you.</em
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Our Services offer you
                                                      the ability to register
                                                      and log in using your
                                                      third-party social media
                                                      account details (like your
                                                      Facebook or X logins).
                                                      Where you choose to do
                                                      this, we will receive
                                                      certain profile
                                                      information about you from
                                                      your social media
                                                      provider. The profile
                                                      information we receive may
                                                      vary depending on the
                                                      social media provider
                                                      concerned, but will often
                                                      include your name, email
                                                      address, friends list, and
                                                      profile picture, as well
                                                      as other information you
                                                      choose to make public on
                                                      such a social media
                                                      platform.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >We will use the
                                                      information we receive
                                                      only for the purposes that
                                                      are described in this
                                                      Privacy Notice or that are
                                                      otherwise made clear to
                                                      you on the relevant
                                                      Services. Please note that
                                                      we do not control, and are
                                                      not responsible for, other
                                                      uses of your personal
                                                      information by your
                                                      third-party social media
                                                      provider. We recommend
                                                      that you review their
                                                      privacy notice to
                                                      understand how they
                                                      collect, use, and share
                                                      your personal information,
                                                      and how you can set your
                                                      privacy preferences on
                                                      their sites and
                                                      apps.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div
                                                id="inforetain"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  style="
                                                    color: rgb(127, 127, 127);
                                                  "
                                                  ><span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          id="control"
                                                          style="
                                                            color: rgb(0, 0, 0);
                                                          "
                                                          ><strong
                                                            ><span
                                                              data-custom-class="heading_1"
                                                              >8. HOW LONG DO WE
                                                              KEEP YOUR
                                                              INFORMATION?</span
                                                            ></strong
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><strong
                                                        ><em
                                                          >In Short:&nbsp;</em
                                                        ></strong
                                                      ><em
                                                        >We keep your
                                                        information for as long
                                                        as necessary to fulfill
                                                        the purposes outlined in
                                                        this Privacy Notice
                                                        unless otherwise
                                                        required by law.</em
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >We will only keep your
                                                      personal information for
                                                      as long as it is necessary
                                                      for the purposes set out
                                                      in this Privacy Notice,
                                                      unless a longer retention
                                                      period is required or
                                                      permitted by law (such as
                                                      tax, accounting, or other
                                                      legal requirements). No
                                                      purpose in this notice
                                                      will require us keeping
                                                      your personal information
                                                      for longer than eighty
                                                      four (84) months past the
                                                      termination of the user's
                                                      account.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >When we have no ongoing
                                                      legitimate business need
                                                      to process your personal
                                                      information, we will
                                                      either delete or anonymize
                                                      such information, or, if
                                                      this is not possible (for
                                                      example, because your
                                                      personal information has
                                                      been stored in backup
                                                      archives), then we will
                                                      securely store your
                                                      personal information and
                                                      isolate it from any
                                                      further processing until
                                                      deletion is
                                                      possible.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div
                                                id="infosafe"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  style="
                                                    color: rgb(127, 127, 127);
                                                  "
                                                  ><span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          id="control"
                                                          style="
                                                            color: rgb(0, 0, 0);
                                                          "
                                                          ><strong
                                                            ><span
                                                              data-custom-class="heading_1"
                                                              >9. HOW DO WE KEEP
                                                              YOUR INFORMATION
                                                              SAFE?</span
                                                            ></strong
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><strong
                                                        ><em
                                                          >In Short:&nbsp;</em
                                                        ></strong
                                                      ><em
                                                        >We aim to protect your
                                                        personal information
                                                        through a system of
                                                        organizational and
                                                        technical security
                                                        measures.</em
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >We have implemented
                                                      appropriate and reasonable
                                                      technical and
                                                      organizational security
                                                      measures designed to
                                                      protect the security of
                                                      any personal information
                                                      we process. However,
                                                      despite our safeguards and
                                                      efforts to secure your
                                                      information, no electronic
                                                      transmission over the
                                                      Internet or information
                                                      storage technology can be
                                                      guaranteed to be 100%
                                                      secure, so we cannot
                                                      promise or guarantee that
                                                      hackers, cybercriminals,
                                                      or other unauthorized
                                                      third parties will not be
                                                      able to defeat our
                                                      security and improperly
                                                      collect, access, steal, or
                                                      modify your information.
                                                      Although we will do our
                                                      best to protect your
                                                      personal information,
                                                      transmission of personal
                                                      information to and from
                                                      our Services is at your
                                                      own risk. You should only
                                                      access the Services within
                                                      a secure
                                                      environment.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div
                                                id="infominors"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  style="
                                                    color: rgb(127, 127, 127);
                                                  "
                                                  ><span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          id="control"
                                                          style="
                                                            color: rgb(0, 0, 0);
                                                          "
                                                          ><strong
                                                            ><span
                                                              data-custom-class="heading_1"
                                                              >10. DO WE COLLECT
                                                              INFORMATION FROM
                                                              MINORS?</span
                                                            ></strong
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><strong
                                                        ><em
                                                          >In Short:</em
                                                        ></strong
                                                      ><em
                                                        >&nbsp;We do not
                                                        knowingly collect data
                                                        from or market to
                                                        children under 18 years
                                                        of age.</em
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >We do not knowingly
                                                      collect, solicit data
                                                      from, or market to
                                                      children under 18 years of
                                                      age, nor do we knowingly
                                                      sell such personal
                                                      information. By using the
                                                      Services, you represent
                                                      that you are at least 18
                                                      or that you are the parent
                                                      or guardian of such a
                                                      minor and consent to such
                                                      minor dependent’s use of
                                                      the Services. If we learn
                                                      that personal information
                                                      from users less than 18
                                                      years of age has been
                                                      collected, we will
                                                      deactivate the account and
                                                      take reasonable measures
                                                      to promptly delete such
                                                      data from our records. If
                                                      you become aware of any
                                                      data we may have collected
                                                      from children under age
                                                      18, please contact us at
                                                      <span
                                                        style="
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                          font-size: 15px;
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >vikram.natarajan@vitatraq.com</span
                                                        ></span
                                                      >.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div
                                                id="privacyrights"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  style="
                                                    color: rgb(127, 127, 127);
                                                  "
                                                  ><span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          id="control"
                                                          style="
                                                            color: rgb(0, 0, 0);
                                                          "
                                                          ><strong
                                                            ><span
                                                              data-custom-class="heading_1"
                                                              >11. WHAT ARE YOUR
                                                              PRIVACY
                                                              RIGHTS?</span
                                                            ></strong
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><strong
                                                        ><em
                                                          >In Short:</em
                                                        ></strong
                                                      ><em
                                                        >&nbsp;Depending on your
                                                        state of residence in
                                                        the US or in some
                                                        regions, such as Canada,
                                                        you have rights that
                                                        allow you greater access
                                                        to and control over your
                                                        personal
                                                        information.<span
                                                          style="
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          >&nbsp;</span
                                                        >You may review, change,
                                                        or terminate your
                                                        account at any time,
                                                        depending on your
                                                        country, province, or
                                                        state of residence.</em
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >In some regions (like
                                                      Canada), you have certain
                                                      rights under applicable
                                                      data protection laws.
                                                      These may include the
                                                      right (i) to request
                                                      access and obtain a copy
                                                      of your personal
                                                      information, (ii) to
                                                      request rectification or
                                                      erasure; (iii) to restrict
                                                      the processing of your
                                                      personal information; (iv)
                                                      if applicable, to data
                                                      portability; and (v) not
                                                      to be subject to automated
                                                      decision-making. In
                                                      certain circumstances, you
                                                      may also have the right to
                                                      object to the processing
                                                      of your personal
                                                      information. You can make
                                                      such a request by
                                                      contacting us by using the
                                                      contact details provided
                                                      in the section "</span
                                                    ></span
                                                  ></span
                                                ><a
                                                  data-custom-class="link"
                                                  href="#contact"
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(0, 58, 250);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(0, 58, 250);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >HOW CAN YOU CONTACT US
                                                        ABOUT THIS NOTICE?</span
                                                      ></span
                                                    ></span
                                                  ></a
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >" below.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >We will consider and act
                                                      upon any request in
                                                      accordance with applicable
                                                      data protection
                                                      laws.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div
                                                id="withdrawconsent"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><strong
                                                        ><u
                                                          >Withdrawing your
                                                          consent:</u
                                                        ></strong
                                                      >
                                                      If we are relying on your
                                                      consent to process your
                                                      personal information,
                                                      which may be express
                                                      and/or implied consent
                                                      depending on the
                                                      applicable law, you have
                                                      the right to withdraw your
                                                      consent at any time. You
                                                      can withdraw your consent
                                                      at any time by contacting
                                                      us by using the contact
                                                      details provided in the
                                                      section "</span
                                                    ></span
                                                  ></span
                                                ><a
                                                  data-custom-class="link"
                                                  href="#contact"
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(0, 58, 250);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(0, 58, 250);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >HOW CAN YOU CONTACT US
                                                        ABOUT THIS NOTICE?</span
                                                      ></span
                                                    ></span
                                                  ></a
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >" below.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >However, please note that
                                                    this will not affect the
                                                    lawfulness of the processing
                                                    before its withdrawal nor,
                                                    when applicable law allows,
                                                    will it affect the
                                                    processing of your personal
                                                    information conducted in
                                                    reliance on lawful
                                                    processing grounds other
                                                    than consent.</span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="heading_2"
                                                    ><strong
                                                      >Account
                                                      Information</strong
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  data-custom-class="body_text"
                                                  ><span style="font-size: 15px"
                                                    >If you would at any time
                                                    like to review or change the
                                                    information in your account
                                                    or terminate your account,
                                                    you can:</span
                                                  ></span
                                                >
                                              </div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span
                                                    data-custom-class="body_text"
                                                    ><span
                                                      style="font-size: 15px"
                                                      >Contact us using the
                                                      contact information
                                                      provided.</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <div
                                                style="line-height: 1.5"
                                              ></div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span
                                                    data-custom-class="body_text"
                                                    ><span
                                                      style="font-size: 15px"
                                                      >Log in to your account
                                                      settings and update your
                                                      user account.</span
                                                    ></span
                                                  >
                                                </li>
                                              </ul>
                                              <div
                                                style="line-height: 1.5"
                                              ></div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >Upon your request to
                                                    terminate your account, we
                                                    will deactivate or delete
                                                    your account and information
                                                    from our active databases.
                                                    However, we may retain some
                                                    information in our files to
                                                    prevent fraud, troubleshoot
                                                    problems, assist with any
                                                    investigations, enforce our
                                                    legal terms and/or comply
                                                    with applicable legal
                                                    requirements.</span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><strong
                                                        ><u
                                                          >Cookies and similar
                                                          technologies:</u
                                                        ></strong
                                                      >
                                                      Most Web browsers are set
                                                      to accept cookies by
                                                      default. If you prefer,
                                                      you can usually choose to
                                                      set your browser to remove
                                                      cookies and to reject
                                                      cookies. If you choose to
                                                      remove cookies or reject
                                                      cookies, this could affect
                                                      certain features or
                                                      services of our
                                                      Services.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  data-custom-class="body_text"
                                                  ><span style="font-size: 15px"
                                                    >If you have questions or
                                                    comments about your privacy
                                                    rights, you may email us at
                                                    support@vitatraq.com.</span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div
                                                id="DNT"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  style="
                                                    color: rgb(127, 127, 127);
                                                  "
                                                  ><span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          id="control"
                                                          style="
                                                            color: rgb(0, 0, 0);
                                                          "
                                                          ><strong
                                                            ><span
                                                              data-custom-class="heading_1"
                                                              >12. CONTROLS FOR
                                                              DO-NOT-TRACK
                                                              FEATURES</span
                                                            ></strong
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >Most web browsers and
                                                      some mobile operating
                                                      systems and mobile
                                                      applications include a
                                                      Do-Not-Track ("DNT")
                                                      feature or setting you can
                                                      activate to signal your
                                                      privacy preference not to
                                                      have data about your
                                                      online browsing activities
                                                      monitored and collected.
                                                      At this stage, no uniform
                                                      technology standard for
                                                      recognizing and
                                                      implementing DNT signals
                                                      has been finalized. As
                                                      such, we do not currently
                                                      respond to DNT browser
                                                      signals or any other
                                                      mechanism that
                                                      automatically communicates
                                                      your choice not to be
                                                      tracked online. If a
                                                      standard for online
                                                      tracking is adopted that
                                                      we must follow in the
                                                      future, we will inform you
                                                      about that practice in a
                                                      revised version of this
                                                      Privacy Notice.</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><br
                                                /></span>
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >California law requires us
                                                    to let you know how we
                                                    respond to web browser DNT
                                                    signals. Because there
                                                    currently is not an industry
                                                    or legal standard for
                                                    recognizing or honoring DNT
                                                    signals, we do not respond
                                                    to them at this time.</span
                                                  ></span
                                                >
                                              </div>
                                              <div
                                                style="line-height: 1.5"
                                              ></div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div
                                                id="uslaws"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  style="
                                                    color: rgb(127, 127, 127);
                                                  "
                                                  ><span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          id="control"
                                                          style="
                                                            color: rgb(0, 0, 0);
                                                          "
                                                          ><strong
                                                            ><span
                                                              data-custom-class="heading_1"
                                                              >13. DO UNITED
                                                              STATES RESIDENTS
                                                              HAVE SPECIFIC
                                                              PRIVACY
                                                              RIGHTS?</span
                                                            ></strong
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><strong
                                                        ><em
                                                          >In Short:&nbsp;</em
                                                        ></strong
                                                      ><em
                                                        >If you are a resident
                                                        of California, Colorado,
                                                        Connecticut, Delaware,
                                                        Florida, Indiana, Iowa,
                                                        Kentucky, Montana, New
                                                        Hampshire, New Jersey,
                                                        Oregon, Tennessee,
                                                        Texas, Utah, or
                                                        Virginia, you may have
                                                        the right to request
                                                        access to and receive
                                                        details about the
                                                        personal information we
                                                        maintain about you and
                                                        how we have processed
                                                        it, correct
                                                        inaccuracies, get a copy
                                                        of, or delete your
                                                        personal information.
                                                        You may also have the
                                                        right to withdraw your
                                                        consent to our
                                                        processing of your
                                                        personal information.
                                                        These rights may be
                                                        limited in some
                                                        circumstances by
                                                        applicable law. More
                                                        information is provided
                                                        below.</em
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><strong
                                                      ><span
                                                        data-custom-class="heading_2"
                                                        >Categories of Personal
                                                        Information We
                                                        Collect</span
                                                      ></strong
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >We have collected the
                                                      following categories of
                                                      personal information in
                                                      the past twelve (12)
                                                      months:</span
                                                    ></span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <table style="width: 100%">
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><strong
                                                              >Category</strong
                                                            ></span
                                                          ></span
                                                        ></span
                                                      >
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-top: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><strong
                                                              >Examples</strong
                                                            ></span
                                                          ></span
                                                        ></span
                                                      >
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                        text-align: center;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><strong
                                                              >Collected</strong
                                                            ></span
                                                          ></span
                                                        ></span
                                                      >
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >A.
                                                              Identifiers</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-top: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Contact details,
                                                              such as real name,
                                                              alias, postal
                                                              address, telephone
                                                              or mobile contact
                                                              number, unique
                                                              personal
                                                              identifier, online
                                                              identifier,
                                                              Internet Protocol
                                                              address, email
                                                              address, and
                                                              account name</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        text-align: center;
                                                        vertical-align: middle;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >YES</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <div
                                                style="line-height: 1.5"
                                              ></div>
                                              <table style="width: 100%">
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >B. Personal
                                                              information as
                                                              defined in the
                                                              California
                                                              Customer Records
                                                              statute</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Name, contact
                                                              information,
                                                              education,
                                                              employment,
                                                              employment
                                                              history, and
                                                              financial
                                                              information</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >YES</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <div
                                                style="line-height: 1.5"
                                              ></div>
                                              <table style="width: 100%">
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >C. Protected
                                                              classification
                                                              characteristics
                                                              under state or
                                                              federal law</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Gender, age, date
                                                              of birth, race and
                                                              ethnicity,
                                                              national origin,
                                                              marital status,
                                                              and other
                                                              demographic
                                                              data</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >YES</span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >D. Commercial
                                                              information</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Transaction
                                                              information,
                                                              purchase history,
                                                              financial details,
                                                              and payment
                                                              information</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >E. Biometric
                                                              information</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Fingerprints and
                                                              voiceprints</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >YES</span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >F. Internet or
                                                              other similar
                                                              network
                                                              activity</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Browsing history,
                                                              search history,
                                                              online behavior,
                                                              interest data, and
                                                              interactions with
                                                              our and other
                                                              websites,
                                                              applications,
                                                              systems, and
                                                              advertisements</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >G. Geolocation
                                                              data</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Device
                                                              location</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >YES</span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >H. Audio,
                                                              electronic,
                                                              sensory, or
                                                              similar
                                                              information</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Images and audio,
                                                              video or call
                                                              recordings created
                                                              in connection with
                                                              our business
                                                              activities</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >YES</span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        width: 33.8274%;
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >I. Professional
                                                              or
                                                              employment-related
                                                              information</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 51.4385%;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Business contact
                                                              details in order
                                                              to provide you our
                                                              Services at a
                                                              business level or
                                                              job title, work
                                                              history, and
                                                              professional
                                                              qualifications if
                                                              you apply for a
                                                              job with us</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        width: 14.9084%;
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                        width: 33.8274%;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >J. Education
                                                              Information</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                        width: 51.4385%;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Student records
                                                              and directory
                                                              information</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                        width: 14.9084%;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        border-width: 1px;
                                                        border-color: black;
                                                        border-style: solid;
                                                        width: 33.8274%;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >K. Inferences
                                                              drawn from
                                                              collected personal
                                                              information</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        border-bottom: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        width: 51.4385%;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Inferences drawn
                                                              from any of the
                                                              collected personal
                                                              information listed
                                                              above to create a
                                                              profile or summary
                                                              about, for
                                                              example, an
                                                              individual’s
                                                              preferences and
                                                              characteristics</span
                                                            ></span
                                                          ></span
                                                        >
                                                      </div>
                                                    </td>
                                                    <td
                                                      style="
                                                        text-align: center;
                                                        border-right: 1px solid
                                                          black;
                                                        border-bottom: 1px solid
                                                          black;
                                                        border-top: 1px solid
                                                          black;
                                                        width: 14.9084%;
                                                      "
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style="
                                                        border-left: 1px solid
                                                          black;
                                                        border-right: 1px solid
                                                          black;
                                                        border-bottom: 1px solid
                                                          black;
                                                        line-height: 1.5;
                                                      "
                                                    >
                                                      <span
                                                        data-custom-class="body_text"
                                                        >L. Sensitive personal
                                                        Information</span
                                                      >
                                                    </td>
                                                    <td
                                                      style="
                                                        border-right: 1px solid
                                                          black;
                                                        border-bottom: 1px solid
                                                          black;
                                                        line-height: 1.5;
                                                      "
                                                    >
                                                      <span
                                                        data-custom-class="body_text"
                                                        >Account login
                                                        information, biometric
                                                        data, health data,
                                                        personal data from a
                                                        known child and precise
                                                        geolocation</span
                                                      >
                                                    </td>
                                                    <td
                                                      style="
                                                        border-right: 1px solid
                                                          black;
                                                        border-bottom: 1px solid
                                                          black;
                                                      "
                                                    >
                                                      <div
                                                        data-empty="true"
                                                        style="
                                                          text-align: center;
                                                        "
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        data-custom-class="body_text"
                                                        data-empty="true"
                                                        style="
                                                          text-align: center;
                                                          line-height: 1.5;
                                                        "
                                                      >
                                                        <span
                                                          data-custom-class="body_text"
                                                          >YES</span
                                                        >
                                                      </div>
                                                      <div
                                                        data-empty="true"
                                                        style="
                                                          text-align: center;
                                                        "
                                                      >
                                                        <br />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <div
                                                style="line-height: 1.5"
                                              ></div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >We only collect sensitive
                                                    personal information, as
                                                    defined by applicable
                                                    privacy laws or the purposes
                                                    allowed by law or with your
                                                    consent. Sensitive personal
                                                    information may be used, or
                                                    disclosed to a service
                                                    provider or contractor, for
                                                    additional, specified
                                                    purposes. You may have the
                                                    right to limit the use or
                                                    disclosure of your sensitive
                                                    personal information. We do
                                                    not collect or process
                                                    sensitive personal
                                                    information for the purpose
                                                    of inferring characteristics
                                                    about you.</span
                                                  ></span
                                                >
                                              </div>
                                              <div style="line-height: 1.5">
                                                <br />
                                              </div>
                                              <div style="line-height: 1.5">
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >We may also collect other
                                                    personal information outside
                                                    of these categories through
                                                    instances where you interact
                                                    with us in person, online,
                                                    or by phone or mail in the
                                                    context of:</span
                                                  ></span
                                                >
                                              </div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span style="font-size: 15px"
                                                    >Receiving help through our
                                                    customer support
                                                    channels;</span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                >
                                                  <span style="font-size: 15px"
                                                    >Participation in customer
                                                    surveys or contests;
                                                    and</span
                                                  >
                                                </li>
                                              </ul>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                >
                                                  <span style="font-size: 15px"
                                                    >Facilitation in the
                                                    delivery of our Services and
                                                    to respond to your
                                                    inquiries.</span
                                                  >
                                                </li>
                                              </ul>
                                              <div style="line-height: 1.5">
                                                <span
                                                  data-custom-class="body_text"
                                                  >We will use and retain the
                                                  collected personal information
                                                  as needed to provide the
                                                  Services or for:</span
                                                >
                                              </div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span
                                                    data-custom-class="body_text"
                                                    >Category A - As long as the
                                                    user has an account with
                                                    us</span
                                                  >
                                                </li>
                                              </ul>
                                              <div
                                                style="line-height: 1.5"
                                              ></div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span
                                                    data-custom-class="body_text"
                                                    >Category B - As long as the
                                                    user has an account with
                                                    us</span
                                                  >
                                                </li>
                                              </ul>
                                              <div
                                                style="line-height: 1.5"
                                              ></div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style="line-height: 1.5"
                                                >
                                                  <span
                                                    data-custom-class="body_text"
                                                    >Category C - As long as the
                                                    user has an account with
                                                    us</span
                                                  >
                                                </li>
                                              </ul>
                                              <div style="line-height: 1.5">
                                                <div
                                                  style="line-height: 1.5"
                                                ></div>
                                                <ul>
                                                  <li
                                                    data-custom-class="body_text"
                                                    style="line-height: 1.5"
                                                  >
                                                    <span
                                                      data-custom-class="body_text"
                                                      >Category E - As long as
                                                      the user has an account
                                                      with us</span
                                                    >
                                                  </li>
                                                </ul>
                                                <div style="line-height: 1.5">
                                                  <div
                                                    style="line-height: 1.5"
                                                  ></div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style="line-height: 1.5"
                                                    >
                                                      <span
                                                        data-custom-class="body_text"
                                                        >Category G - As long as
                                                        the user has an account
                                                        with us</span
                                                      >
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style="line-height: 1.5"
                                                  ></div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style="line-height: 1.5"
                                                    >
                                                      <span
                                                        data-custom-class="body_text"
                                                        >Category H - As long as
                                                        the user has an account
                                                        with us</span
                                                      >
                                                    </li>
                                                  </ul>
                                                  <div style="line-height: 1.5">
                                                    <div
                                                      style="line-height: 1.5"
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              data-custom-class="body_text"
                                                              >Category L - As
                                                              long as the user
                                                              has an account
                                                              with us</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <strong
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="heading_2"
                                                                >Sources of
                                                                Personal
                                                                Information</span
                                                              ></span
                                                            ></strong
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Learn more about
                                                              the sources of
                                                              personal
                                                              information we
                                                              collect in "</span
                                                            ></span
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><a
                                                              data-custom-class="link"
                                                              href="#infocollect"
                                                              ><span
                                                                style="
                                                                  color: rgb
                                                                    (0, 58, 250);
                                                                  font-size: 15px;
                                                                "
                                                                >WHAT
                                                                INFORMATION DO
                                                                WE
                                                                COLLECT?</span
                                                              ></a
                                                            ></span
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >"</span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><strong
                                                                ><span
                                                                  data-custom-class="heading_2"
                                                                  >How We Use
                                                                  and Share
                                                                  Personal
                                                                  Information</span
                                                                ></strong
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            data-custom-class="body_text"
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            >Learn about how we
                                                            use your personal
                                                            information in the
                                                            section, "</span
                                                          ><a
                                                            data-custom-class="link"
                                                            href="#infouse"
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  0,
                                                                  58,
                                                                  250
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              >HOW DO WE PROCESS
                                                              YOUR
                                                              INFORMATION?</span
                                                            ></a
                                                          ><span
                                                            data-custom-class="body_text"
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            >"</span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  >Will your
                                                                  information be
                                                                  shared with
                                                                  anyone
                                                                  else?</strong
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We may disclose
                                                                your personal
                                                                information with
                                                                our service
                                                                providers
                                                                pursuant to a
                                                                written contract
                                                                between us and
                                                                each service
                                                                provider. Learn
                                                                more about how
                                                                we disclose
                                                                personal
                                                                information to
                                                                in the section,
                                                                "</span
                                                              ></span
                                                            ></span
                                                          ><a
                                                            data-custom-class="link"
                                                            href="#whoshare"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  0,
                                                                  58,
                                                                  250
                                                                );
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    0,
                                                                    58,
                                                                    250
                                                                  );
                                                                "
                                                                >WHEN AND WITH
                                                                WHOM DO WE SHARE
                                                                YOUR PERSONAL
                                                                INFORMATION?</span
                                                              ></span
                                                            ></a
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >"</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We may use your
                                                                personal
                                                                information for
                                                                our own business
                                                                purposes, such
                                                                as for
                                                                undertaking
                                                                internal
                                                                research for
                                                                technological
                                                                development and
                                                                demonstration.
                                                                This is not
                                                                considered to be
                                                                "selling" of
                                                                your personal
                                                                information.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We have not
                                                                disclosed, sold,
                                                                or shared any
                                                                personal
                                                                information to
                                                                third parties
                                                                for a business
                                                                or commercial
                                                                purpose in the
                                                                preceding twelve
                                                                (12) months.
                                                                We<span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  >&nbsp;</span
                                                                >will not sell
                                                                or share
                                                                personal
                                                                information in
                                                                the future
                                                                belonging to
                                                                website
                                                                visitors, users,
                                                                and other
                                                                consumers.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><strong
                                                              ><span
                                                                data-custom-class="heading_2"
                                                                >Your
                                                                Rights</span
                                                              ></strong
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><br
                                                          /></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >You have rights
                                                              under certain US
                                                              state data
                                                              protection laws.
                                                              However, these
                                                              rights are not
                                                              absolute, and in
                                                              certain cases, we
                                                              may decline your
                                                              request as
                                                              permitted by law.
                                                              These rights
                                                              include:</span
                                                            ></span
                                                          >
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><strong
                                                                >Right to
                                                                know</strong
                                                              >
                                                              whether or not we
                                                              are processing
                                                              your personal
                                                              data</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><strong
                                                                >Right to
                                                                access&nbsp;</strong
                                                              >your personal
                                                              data</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><strong
                                                                >Right to
                                                                correct&nbsp;</strong
                                                              >inaccuracies in
                                                              your personal
                                                              data</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><strong
                                                                >Right to
                                                                request</strong
                                                              >
                                                              the deletion of
                                                              your personal
                                                              data</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><strong
                                                                >Right to obtain
                                                                a
                                                                copy&nbsp;</strong
                                                              >of the personal
                                                              data you
                                                              previously shared
                                                              with us</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><strong
                                                                >Right to
                                                                non-discrimination</strong
                                                              >
                                                              for exercising
                                                              your rights</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><strong
                                                                >Right to opt
                                                                out</strong
                                                              >
                                                              of the processing
                                                              of your personal
                                                              data if it is used
                                                              for targeted
                                                              advertising (or
                                                              sharing as defined
                                                              under California’s
                                                              privacy law), the
                                                              sale of personal
                                                              data, or profiling
                                                              in furtherance of
                                                              decisions that
                                                              produce legal or
                                                              similarly
                                                              significant
                                                              effects
                                                              ("profiling")</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Depending upon
                                                              the state where
                                                              you live, you may
                                                              also have the
                                                              following
                                                              rights:</span
                                                            ></span
                                                          >
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              >Right to obtain a
                                                              list of the
                                                              categories of
                                                              third parties to
                                                              which we have
                                                              disclosed personal
                                                              data (as permitted
                                                              by applicable law,
                                                              including
                                                              California's and
                                                              Delaware's privacy
                                                              law)</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              >Right to obtain a
                                                              list of specific
                                                              third parties to
                                                              which we have
                                                              disclosed personal
                                                              data (as permitted
                                                              by applicable law,
                                                              including Oregon’s
                                                              privacy law)</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              >Right to limit
                                                              use and disclosure
                                                              of sensitive
                                                              personal data (as
                                                              permitted by
                                                              applicable law,
                                                              including
                                                              California’s
                                                              privacy law)</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              >Right to opt out
                                                              of the collection
                                                              of sensitive data
                                                              and personal data
                                                              collected through
                                                              the operation of a
                                                              voice or facial
                                                              recognition
                                                              feature (as
                                                              permitted by
                                                              applicable law,
                                                              including
                                                              Florida’s privacy
                                                              law)</span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        ></div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <strong
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="heading_2"
                                                                >How to Exercise
                                                                Your
                                                                Rights</span
                                                              ></span
                                                            ></strong
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >To exercise
                                                                these rights,
                                                                you can contact
                                                                us by visiting
                                                                <span
                                                                  style="
                                                                    color: rgb(
                                                                      0,
                                                                      58,
                                                                      250
                                                                    );
                                                                  "
                                                                  ><a
                                                                    href="https://www.vitatraq.com/contact"
                                                                    target="_blank"
                                                                    data-custom-class="link"
                                                                    >https://www.vitatraq.com/contact</a
                                                                  ></span
                                                                >,</span
                                                              >
                                                              <span
                                                                style="
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                  font-size: 15px;
                                                                "
                                                                ><span
                                                                  data-custom-class="body_text"
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                      font-size: 15px;
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      >by
                                                                      emailing
                                                                      us at
                                                                      support@vitatraq.com,</span
                                                                    >
                                                                    <span
                                                                      data-custom-class="body_text"
                                                                      >by
                                                                      visiting
                                                                      <span
                                                                        style="
                                                                          color: rgb(
                                                                            0,
                                                                            58,
                                                                            250
                                                                          );
                                                                        "
                                                                        ><a
                                                                          href="https://www.vitatraq.com/contact"
                                                                          target="_blank"
                                                                          data-custom-class="link"
                                                                          >https://www.vitatraq.com/contact</a
                                                                        ></span
                                                                      >,</span
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                          <span
                                                            data-custom-class="body_text"
                                                            >or by referring to
                                                            the contact details
                                                            at the bottom of
                                                            this document.</span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Under certain US
                                                              state data
                                                              protection laws,
                                                              you can designate
                                                              an authorized
                                                              agent to make a
                                                              request on your
                                                              behalf. We may
                                                              deny a request
                                                              from an authorized
                                                              agent that does
                                                              not submit proof
                                                              that they have
                                                              been validly
                                                              authorized to act
                                                              on your behalf in
                                                              accordance with
                                                              applicable
                                                              laws.</span
                                                            ><br
                                                          /></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><strong
                                                              ><span
                                                                data-custom-class="heading_2"
                                                                >Request
                                                                Verification</span
                                                              ></strong
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><br
                                                          /></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Upon receiving
                                                              your request, we
                                                              will need to
                                                              verify your
                                                              identity to
                                                              determine you are
                                                              the same person
                                                              about whom we have
                                                              the information in
                                                              our system. We
                                                              will only use
                                                              personal
                                                              information
                                                              provided in your
                                                              request to verify
                                                              your identity or
                                                              authority to make
                                                              the request.
                                                              However, if we
                                                              cannot verify your
                                                              identity from the
                                                              information
                                                              already maintained
                                                              by us, we may
                                                              request that you
                                                              provide additional
                                                              information for
                                                              the purposes of
                                                              verifying your
                                                              identity and for
                                                              security or
                                                              fraud-prevention
                                                              purposes.</span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><br
                                                          /></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >If you submit the
                                                              request through an
                                                              authorized agent,
                                                              we may need to
                                                              collect additional
                                                              information to
                                                              verify your
                                                              identity before
                                                              processing your
                                                              request and the
                                                              agent will need to
                                                              provide a written
                                                              and signed
                                                              permission from
                                                              you to submit such
                                                              request on your
                                                              behalf.</span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><br
                                                          /></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="heading_2"
                                                              ><strong
                                                                >Appeals</strong
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><br
                                                          /></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Under certain US
                                                              state data
                                                              protection laws,
                                                              if we decline to
                                                              take action
                                                              regarding your
                                                              request, you may
                                                              appeal our
                                                              decision by
                                                              emailing us at
                                                              support@vitatraq.com.
                                                              We will inform you
                                                              in writing of any
                                                              action taken or
                                                              not taken in
                                                              response to the
                                                              appeal, including
                                                              a written
                                                              explanation of the
                                                              reasons for the
                                                              decisions. If your
                                                              appeal is denied,
                                                              you may submit a
                                                              complaint to your
                                                              state attorney
                                                              general.</span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><br
                                                          /></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><strong
                                                              ><span
                                                                data-custom-class="heading_2"
                                                                >California
                                                                "Shine The
                                                                Light" Law</span
                                                              ></strong
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><br
                                                          /></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >California Civil
                                                              Code Section
                                                              1798.83, also
                                                              known as the
                                                              "Shine The Light"
                                                              law, permits our
                                                              users who are
                                                              California
                                                              residents to
                                                              request and obtain
                                                              from us, once a
                                                              year and free of
                                                              charge,
                                                              information about
                                                              categories of
                                                              personal
                                                              information (if
                                                              any) we disclosed
                                                              to third parties
                                                              for direct
                                                              marketing purposes
                                                              and the names and
                                                              addresses of all
                                                              third parties with
                                                              which we shared
                                                              personal
                                                              information in the
                                                              immediately
                                                              preceding calendar
                                                              year. If you are a
                                                              California
                                                              resident and would
                                                              like to make such
                                                              a request, please
                                                              submit your
                                                              request in writing
                                                              to us by using the
                                                              contact details
                                                              provided in the
                                                              section "</span
                                                            ></span
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><a
                                                              data-custom-class="link"
                                                              href="#contact"
                                                              ><span
                                                                style="
                                                                  color: rgb(
                                                                    0,
                                                                    58,
                                                                    250
                                                                  );
                                                                  font-size: 15px;
                                                                "
                                                                >HOW CAN YOU
                                                                CONTACT US ABOUT
                                                                THIS
                                                                NOTICE?</span
                                                              ></a
                                                            ></span
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >"</span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="policyupdates"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >14. DO
                                                                        WE MAKE
                                                                        UPDATES
                                                                        TO THIS
                                                                        NOTICE?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><em
                                                                  ><strong
                                                                    >In
                                                                    Short:&nbsp;</strong
                                                                  >Yes, we will
                                                                  update this
                                                                  notice as
                                                                  necessary to
                                                                  stay compliant
                                                                  with relevant
                                                                  laws.</em
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We may update
                                                                this Privacy
                                                                Notice from time
                                                                to time. The
                                                                updated version
                                                                will be
                                                                indicated by an
                                                                updated
                                                                "Revised" date
                                                                at the top of
                                                                this Privacy
                                                                Notice. If we
                                                                make material
                                                                changes to this
                                                                Privacy Notice,
                                                                we may notify
                                                                you either by
                                                                prominently
                                                                posting a notice
                                                                of such changes
                                                                or by directly
                                                                sending you a
                                                                notification. We
                                                                encourage you to
                                                                review this
                                                                Privacy Notice
                                                                frequently to be
                                                                informed of how
                                                                we are
                                                                protecting your
                                                                information.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="contact"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >15. HOW
                                                                        CAN YOU
                                                                        CONTACT
                                                                        US ABOUT
                                                                        THIS
                                                                        NOTICE?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >If you have
                                                                questions or
                                                                comments about
                                                                this notice, you
                                                                may contact our
                                                                Data Protection
                                                                Officer
                                                                (DPO)<span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      font-size: 15px;
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      >&nbsp;by
                                                                      email
                                                                      at&nbsp;</span
                                                                    ></span
                                                                  ></span
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    >vikram.natarajan@vitatraq.com</span
                                                                  ></span
                                                                >,<span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      font-size: 15px;
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      >&nbsp;by
                                                                      phone at
                                                                      +1-919-413-3269,</span
                                                                    ></span
                                                                  ></span
                                                                >
                                                                <span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    >or</span
                                                                  ></span
                                                                >
                                                                <span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      font-size: 15px;
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      >contact
                                                                      us by post
                                                                      at:</span
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        data-custom-class="body_text"
                                                                        >Vitatraq
                                                                        LLC</span
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Data Protection
                                                              Officer</span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                style="
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  data-custom-class="body_text"
                                                                  >213, Cole
                                                                  Valley
                                                                  Drive</span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            data-custom-class="body_text"
                                                            >Cary<span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >, NC</span
                                                              >
                                                              27513</span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >United
                                                                States</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="request"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >16. HOW
                                                                        CAN YOU
                                                                        REVIEW,
                                                                        UPDATE,
                                                                        OR
                                                                        DELETE
                                                                        THE DATA
                                                                        WE
                                                                        COLLECT
                                                                        FROM
                                                                        YOU?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >Based on the
                                                                applicable laws
                                                                of your country
                                                                or state of
                                                                residence in the
                                                                US, you may have
                                                                the right to
                                                                request access
                                                                to the personal
                                                                information we
                                                                collect from
                                                                you, details
                                                                about how we
                                                                have processed
                                                                it, correct
                                                                inaccuracies, or
                                                                delete your
                                                                personal
                                                                information. You
                                                                may also have
                                                                the right to
                                                                withdraw your
                                                                consent to our
                                                                processing of
                                                                your personal
                                                                information.
                                                                These rights may
                                                                be limited in
                                                                some
                                                                circumstances by
                                                                applicable law.
                                                                To request to
                                                                review, update,
                                                                or delete your
                                                                personal
                                                                information,
                                                                please</span
                                                              >
                                                              <span
                                                                data-custom-class="body_text"
                                                                >visit:
                                                                <span
                                                                  style="
                                                                    color: rgb(
                                                                      0,
                                                                      58,
                                                                      250
                                                                    );
                                                                  "
                                                                  ><a
                                                                    href="https://www.vitatraq.com/contact"
                                                                    target="_blank"
                                                                    data-custom-class="link"
                                                                    >https://www.vitatraq.com/contact</a
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >.</span
                                                            ></span
                                                          >
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
[data-custom-class="body"],
[data-custom-class="body"] * {
  background: transparent !important;
}
[data-custom-class="title"],
[data-custom-class="title"] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}
[data-custom-class="subtitle"],
[data-custom-class="subtitle"] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}
[data-custom-class="heading_1"],
[data-custom-class="heading_1"] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}
[data-custom-class="heading_2"],
[data-custom-class="heading_2"] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}
[data-custom-class="body_text"],
[data-custom-class="body_text"] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}
[data-custom-class="link"],
[data-custom-class="link"] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}
ul {
  list-style-type: square;
}
ul > li > ul {
  list-style-type: circle;
}
ul > li > ul > li > ul {
  list-style-type: square;
}
ol li {
  font-family: Arial;
}
</style>
