import gql from "graphql-tag";

export const ORG_CONSENT_TEMPLATE_TOKEN = gql`
  query templateRevealToken($templateId: Int) {
    templateRevealToken(templateId: $templateId)
  }
`;

export const CREATE_ORG_CONSENT_TEMPLATE = gql`
  mutation saveOrganizationTemplate(
    $orgConsentTemplateInput: OrgConsentTemplateInput!
  ) {
    saveOrganizationTemplate(orgConsentTemplateInput: $orgConsentTemplateInput)
  }
`;

export const ORG_CONSENT_TEMPLATES = gql`
  query orgConsentTemplates($orgId: String!) {
    orgConsentTemplates(orgId: $orgId) {
      templateId
      organizationId
      templateName
      isActive
      isEdited
      slug
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_DOCUSEAL_DOCUMENT = gql`
  mutation deleteOrgTemplate($templateId: Int!) {
    deleteOrgTemplate(templateId: $templateId)
  }
`;

export const UPDATE_ORG_TEMPLATE_ACTIVE_STATUS = gql`
  mutation updateOrgTemplateStatus($templateId: Int!, $isActive: Boolean!) {
    updateOrgTemplateStatus(templateId: $templateId, isActive: $isActive)
  }
`;

export const UPDATE_ORG_CONSENT_TEMPLATE = gql`
  mutation updateOrganizationTemplate(
    $organizationId: String!
    $templateId: Int!
    $templateName: String!
  ) {
    updateOrganizationTemplate(
      organizationId: $organizationId
      templateId: $templateId
      templateName: $templateName
    ) {
      templateId
      organizationId
      templateName
      isEdited
      slug
      createdAt
      updatedAt
      isActive
    }
  }
`;

export const MULTI_PATIENT_OTHER_CONSENTS = gql`
 query listOrgPatientsConsent($orgPatientConsentsFilter: OrgPatientConsentsFilter, $limit: Int, $offset: Int){
  listOrgPatientsConsent(orgPatientConsentsFilter: $orgPatientConsentsFilter, limit: $limit, offset: $offset){
    orgPatientConsent {
      patientId
      firstName
      lastName
      isActive
      email
      dob
      consent {
        submissionId
        templateId
        templateName
        slug
        isActive
      }
    }
    count
  }
 }
`
