<template>
  <v-card elevation="4">
    <v-row>
      <v-col class="pt-5" cols="12">
        <div class="text-right">
          <v-btn icon="mdi-close-thick" color="primary" class="task-details-action" @click="$emit('close')"
          variant="tonal" rounded size="small" elevation="3" />
        </div>
      </v-col>
      <v-col cols="12" class="pa-4">
        <v-card-item class="mx-auto">
          <v-btn-toggle v-model="toggle" style="overflow-x: visible;" color="primary" :rounded="false"
            density="compact">
            <v-btn v-for="signedConsent in signedConsents" :key="signedConsent.name" :rounded="false" density="compact"
              @click="openSelectedPdf(signedConsent.url)">
              {{ signedConsent.name }}
            </v-btn>
          </v-btn-toggle>
          <div class="pa-10">
            <v-skeleton-loader height="800" class="mx-12" v-if="loading"
              type="table-heading, image, table-tfoot"></v-skeleton-loader>
            <VuePdfEmbed @loaded="() => { loading = false }" :scale="0.5" :source="pdfSource" />
          </div>
        </v-card-item>
      </v-col>
    </v-row>
  </v-card>

</template>

<script setup lang="ts">
import { ISignedConsentInfo } from '@/interfaces/consentTemplate/patientConsents.interface';
import { PropType, ref, watch } from 'vue';
import VuePdfEmbed from 'vue-pdf-embed';

const props = defineProps({
  loadPatientSignedConsentUrl: {
    type: Function as PropType<(submissionId: number) => Promise<ISignedConsentInfo[]>>,
    required: true,
  },
  submissionId: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(['close'])

const pdfSource = ref('');
const toggle = ref(0);
const signedConsents = ref<ISignedConsentInfo[]>([]);
const loading = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('');

const previewSignedConsents = async (submissionId: number) => {
  try {
    loading.value = true;
    const response = await props.loadPatientSignedConsentUrl(submissionId);
    signedConsents.value = response;
    pdfSource.value = response[0].url;
  } catch (error) {
    loading.value = false;
    showErrorMessage.value = true;
    errorMessage.value = (error as Error).message;
  }
};

const openSelectedPdf = (url: string) => {
  pdfSource.value = url;
};

watch(() => props.submissionId,(newSubmissionId) => {
    if (newSubmissionId) {
      previewSignedConsents(newSubmissionId);
    }
  },{ immediate: true }
);
</script>
