<template>
  <v-card class="org-list-card h-100 mx-2" flat color="surfContainer" elevation="2">
    <v-card-item class="bg-surface pb-0 mb-4">
      <template v-slot:title>
        <div class="d-flex align-center items-center text-primary">
          <v-icon size="large">mdi-file-sign</v-icon>
          <div class="d-flex flex-wrap"> <span class="ml-4">Organization Consent Templates</span>
            <span class="ml-4 v-card-subtitle flex-1-1-100">Add and manage templates for consents in your
              organization</span>
          </div>
          <v-spacer />
          <v-btn variant="elevated" rounded="false" @click="openTemplateCreate = true">
            <v-icon size="large">mdi-plus</v-icon> <span>Add consent template</span>
          </v-btn>
        </div>
        <v-divider thickness="1" class="my-2" />
      </template>
    </v-card-item>
    <v-card-item>
      <v-data-table :loading="loading" :headers="headers" :items="orgConsentTemplates" item-key="name"
        items-per-page="5">
        <template v-slot:[`item.isActive`]="{ item }">
          <v-chip :color="item.isActive ? 'success' : 'error'" :rounded="false">
            {{ item.isActive ? "Active" : "Inactive" }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn :disabled="!item.isActive" class="text-none font-weight-bold mr-2" rounded="false" variant="tonal"
            size="small" @click="updateOrgTemplateHandler(item)">
            <v-icon>mdi-pencil</v-icon>
            <v-tooltip activator="parent" location="bottom">
              Edit
            </v-tooltip>
          </v-btn>
          <v-menu location="right">
            <template v-slot:activator="{ props }">
              <v-btn icon="mdi-dots-vertical" :rounded="false" variant="text" v-bind="props"></v-btn>
            </template>
            <v-list density="compact">
              <v-list-item class="cursor-pointer text-primary"
                @click="updateActiveStatus(item.templateId, !item.isActive)">
                {{ item.isActive ? "Deactivate" : "Activate" }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-item>
    <v-dialog persistent class="mx-16" v-model="openTemplateCreate">
      <OrgConsentTemplateForm :org-consent-template="selectedOrgConsentTemplate"
        :is-editing-template="isEditingTemplate" :create-org-consent-template="createOrgConsentTemplate"
        @close-form="() => { openTemplateCreate = false; isEditingTemplate = false }"
        @toke-generate-error="tokenGenerationError" @consent-template-edited="consentTemplateEdited"
        :organization-consent-template-token="organizationConsentTemplateToken"
        :save-organization-template-changes="saveOrganizationTemplateChanges" @consent-template-saved="consentTemplateSaved"
        :delete-docuseal-org-template="deleteDocusealOrgTemplate" />
    </v-dialog>
  </v-card>
  <v-snackbar color="error" class="text-white" v-model="showErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="success" class="text-white" v-model="showSuccessMessage" location="top right">
    {{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script lang="ts" setup>
import { onMounted, PropType, ref } from 'vue';
import OrgConsentTemplateForm from './OrgConsentTemplateForm.vue';
import { IOrgConsentTemplate, IOrgConsentTemplateInput } from '@/interfaces/consentTemplate/orgConsentTemplate.interface';
import { useRoute } from 'vue-router';
import moment from 'moment';

const headers = ref([
  { title: 'Template Name', value: 'templateName' },
  { title: 'Template ID', value: 'templateId' },
  { title: 'Slug ID', value: 'slug' },
  { title: 'Created At', value: 'createdAt' },
  { title: 'Updated At', value: 'updatedAt' },
  { title: 'Status', value: 'isActive' },
  { title: 'Action', value: 'actions' }
]);

const showErrorMessage = ref(false);
const errorMessage = ref('');
const showSuccessMessage = ref(false);
const successMessage = ref('');
const openTemplateCreate = ref(false);
const orgConsentTemplates = ref([] as IOrgConsentTemplate[]);
const route = useRoute();
const orgId = ref(route.params["orgId"]);
const loading = ref(false);
const selectedOrgConsentTemplate = ref({} as IOrgConsentTemplate | null);
const isEditingTemplate = ref(false);

const props = defineProps({
  organizationConsentTemplateToken: {
    type: Function as PropType<(templateId?: number) => Promise<string>>,
    required: true,
  },
  createOrgConsentTemplate: {
    type: Function as PropType<(orgConsentTemplateInput: IOrgConsentTemplateInput) => Promise<boolean>>,
    required: true,
  },
  fetchingOrgConsentTemplate: {
    type: Function as PropType<(orgId?: string) => Promise<IOrgConsentTemplate[]>>,
    required: true,
  },
  deleteDocusealOrgTemplate: {
    type: Function as PropType<(tempalteId: number) => Promise<boolean>>,
    required: true,
  },
  updategOrgTemplateStatus: {
    type: Function as PropType<(templateId: number, isActive: boolean) => Promise<boolean>>,
    required: true,
  },
  saveOrganizationTemplateChanges: {
    type: Function as PropType<(organizationId: string, templateId: number, templateName: string) => Promise<IOrgConsentTemplate>>,
    required: true,
  }
})

const tokenGenerationError = () => {
  showErrorMessage.value = true;
  errorMessage.value = 'Failed to open the interface to add new template. Please try again'
}

const fetchOrgConsentTemplates = () => {
  loading.value = true;
  props.fetchingOrgConsentTemplate(orgId.value as string).then((response) => {
    orgConsentTemplates.value = response.map((orgConsentTemplate) => {
      return {
        ...orgConsentTemplate,
        createdAt: moment(orgConsentTemplate.createdAt).format('MM/DD/YY hh:mmA'),
        updatedAt: moment(orgConsentTemplate.updatedAt).format('MM/DD/YY hh:mmA'),
      }
    });
  }).catch((error: Error) => {
    showErrorMessage.value = true;
    errorMessage.value = error.message;
  }).finally(() => loading.value = false)
};

const consentTemplateSaved = () => {
  openTemplateCreate.value = false;
  showSuccessMessage.value = true;
  successMessage.value = 'Organization template created successfully';
  fetchOrgConsentTemplates();
}

const consentTemplateEdited = () => {
  openTemplateCreate.value = false;
  showSuccessMessage.value = true;
  successMessage.value = 'Organization template Updated successfully';
  isEditingTemplate.value = false;
  selectedOrgConsentTemplate.value = null;
  fetchOrgConsentTemplates();
}

const updateActiveStatus = (templateId: number, isActive: boolean) => {
  loading.value = true;
  props.updategOrgTemplateStatus(templateId, isActive).then((response) => {
    const activeStatus = isActive ? 'activated' : 'deactivated';
    showSuccessMessage.value = !!response;
    successMessage.value = `Organization template ${activeStatus} successfully`;
    fetchOrgConsentTemplates();
  }).catch((error: Error) => {
    loading.value = false
    showErrorMessage.value = true;
    errorMessage.value = error.message;
  })
}

const updateOrgTemplateHandler = (orgConsentTemplate: IOrgConsentTemplate) => {
  selectedOrgConsentTemplate.value = orgConsentTemplate;
  isEditingTemplate.value = true;
  openTemplateCreate.value = true;
}

onMounted(() => {
  fetchOrgConsentTemplates();
})
</script>
