import gql from "graphql-tag";

export const CREATE_PATIENT_SIGNED_CONSENT = gql`
  mutation savePatientSingedConsent(
    $patientSingedConsentInput: PatientSingedConsentInput!
  ) {
    savePatientSingedConsent(patientSingedConsentInput: $patientSingedConsentInput)
  }
`;

export const PATIENT_SIGNED_CONSENTS = gql`
  query patientSignedConsents($patientId: String!) {
    patientSignedConsents(patientId: $patientId) {
      patientId
      patientFirstName
      patientLastName
      patientEmail
      templateId
      templateName
      organizationId
      submissionSlug
      submissionId
      url
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const PATIENT_SIGNED_CONSENT_URL = gql`
 query loadPatientSignedConsentUrl($submissionId: Int!){
  signedSubmissionUrlById(submissionId: $submissionId){
    name
    url
  }
 }
`
