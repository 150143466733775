<template>
  <v-skeleton-loader height="800" class="mx-12" v-if="loading" type="table-heading, image, table-tfoot"></v-skeleton-loader>
  <v-card v-else elevation="4">
    <v-row>
      <v-col class="pt-5" cols="12">
        <div class="text-right">
          <v-btn icon="mdi-close-thick" color="primary" class="task-details-action" @click="isAlertMsg = true"
          variant="tonal" rounded size="small" elevation="3" />
        </div>
      </v-col>
      <v-col cols="12" class="pa-4">
        <DocusealBuilder :with-documents-list="true" v-if="isTokenUser" @load="handleTemplateLoaded" @save="manageTemplateCreationAndUpdate"
          :autosave="false" :with-title="true" :with-sign-yourself-button="false" :with-send-button="false"
          :token="token" custom-css="
              #save_button { background-color: #6750A4; border-radius: 0px; box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2) }
              .opacity-0 { opacity: 10}
              .font-semibold {font-weight: 400; font-size: 24px} " />
      </v-col>
    </v-row>
  </v-card>
  <v-dialog width="400px" v-model="isAlertMsg">
    <v-card>
      <v-card-title class="text-primary">Confirm</v-card-title>
      <v-card-item> Unsaved changes will be lost. Are you sure you want to exit creating a template? </v-card-item>
      <v-card-actions class="px-4">
        <v-btn elevation="2" rounded="false" class="mx-2" density="compact" variant="tonal" @click="isAlertMsg = false">
          No</v-btn>
        <v-btn elevation="2" rounded="false" density="compact" variant="flat" @click="deleteDocumentInDocuSeal"
          color="primary">yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar color="error" class="text-white" v-model="showErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script setup lang="ts">
import { IOrgConsentTemplate, IOrgConsentTemplateInput } from "@/interfaces/consentTemplate/orgConsentTemplate.interface";
import { DocusealBuilder } from "@docuseal/vue";
import { isEmpty } from "lodash";
import { onMounted, PropType, ref } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
  organizationConsentTemplateToken: {
    type: Function as PropType<(templateId?: number) => Promise<string>>,
    required: true,
  },
  createOrgConsentTemplate: {
    type: Function as PropType<(orgConsentTemplateInput: IOrgConsentTemplateInput) => Promise<boolean>>,
    required: true,
  },
  deleteDocusealOrgTemplate: {
    type: Function as PropType<(tempalteId: number) => Promise<boolean>>,
    required: true,
  },
  isEditingTemplate: {
    type: Boolean,
    required: true,
    default: false
  },
  orgConsentTemplate: {
    type: Object as PropType<IOrgConsentTemplate | null>,
    required: true,
  },
  saveOrganizationTemplateChanges: {
    type: Function as PropType<(organizationId: string, templateId: number, templateName: string) => Promise<IOrgConsentTemplate>>,
    required: true,
  }
});

const { isEditingTemplate, orgConsentTemplate } = props;

const emits = defineEmits(["closeForm", "tokeGenerateError", "consentTemplateSaved", "consentTemplateEdited"]);

const isTokenUser = ref(false);
const token = ref("");
const loading = ref(false);
const isAlertMsg = ref(false);
const route = useRoute();
const orgId = ref(route.params["orgId"]);
const showErrorMessage = ref(false);
const errorMessage = ref('');

const organizationTemplateInput = ref({
  slug: '',
  templateId: 0,
})

const handleTemplateLoaded = (data: any) => {
  organizationTemplateInput.value.templateId = data.id;
  organizationTemplateInput.value.slug = data.slug;
};

const orgTemplateToken = async () => {
  loading.value = true;
  await props.organizationConsentTemplateToken(isEditingTemplate ? orgConsentTemplate?.templateId : undefined)
    .then((response) => {
      token.value = response;
      isTokenUser.value = true;
    })
    .catch(() => { emits('tokeGenerateError') })
    .finally(() => (loading.value = false));
};

const deleteDocumentInDocuSeal = async () => {
  emits("closeForm");
  if (!isEditingTemplate) {
    await props.deleteDocusealOrgTemplate(organizationTemplateInput.value.templateId);
  }
};


const manageTemplateCreationAndUpdate = (data: any) => {
  if (isEditingTemplate) {
    props.saveOrganizationTemplateChanges(orgId.value as string, data.id, data.name)
      .then(() => {
        emits('consentTemplateEdited')
      }).catch((error) => {
        showErrorMessage.value = true;
        errorMessage.value = error.message;
      })
  }
  else {
    orgConsentTemplateCreation(data)
  }
}

const orgConsentTemplateCreation = (data: any) => {
  if (isEmpty(data.documents)) {
    return;
  }
  props.createOrgConsentTemplate({
    ...organizationTemplateInput.value,
    organizationId: orgId.value as string,
    templateName: data.name,
    externalId: data.external_id
  }).then(() => {
    emits("consentTemplateSaved");
  }).catch((error) => {
    showErrorMessage.value = true;
    errorMessage.value = error.message;
  })
}

onMounted(() => { orgTemplateToken(); });
</script>
