export enum TIME_WINDOW {
  MINS="Minutes",
  HRS="Hours",
  DAYS="Days",
  WEEKS="Weeks",
}

export enum ORG_CONSENT_FEATURE {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}
