import {
  CREATE_ORG_CONSENT_TEMPLATE,
  DELETE_DOCUSEAL_DOCUMENT,
  MULTI_PATIENT_OTHER_CONSENTS,
  ORG_CONSENT_TEMPLATE_TOKEN,
  ORG_CONSENT_TEMPLATES,
  UPDATE_ORG_CONSENT_TEMPLATE,
  UPDATE_ORG_TEMPLATE_ACTIVE_STATUS,
} from "@/graphql/resources/consentTemplate/orgConsentTemplateRepository";
import {
  IOrgConsentTemplateInput,
  IOrgPatientConsentsFilter,
} from "@/interfaces/consentTemplate/orgConsentTemplate.interface";
import { apolloClient } from "@/plugins/graphqlclient";

export const orgConsentTemplateToken = async (templateId?: number) => {
  const response = await apolloClient.query({
    query: ORG_CONSENT_TEMPLATE_TOKEN,
    variables: { templateId },
    fetchPolicy: "network-only",
  });

  return response.data.templateRevealToken;
};

export const createOrgConsentTemplate = async (
  orgConsentTemplateInput: IOrgConsentTemplateInput
) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_ORG_CONSENT_TEMPLATE,
    variables: { orgConsentTemplateInput },
  });

  return response.data;
};

export const fetchOrgConsentTemplates = async (orgId: string) => {
  const response = await apolloClient.query({
    query: ORG_CONSENT_TEMPLATES,
    variables: { orgId },
    fetchPolicy: "network-only",
  });

  return response.data.orgConsentTemplates;
}

export const deleteDocuSealOrgTemplate = async (templateId: number) => {
  const response = await apolloClient.mutate({mutation : DELETE_DOCUSEAL_DOCUMENT, variables : { templateId}})
  return response.data.deleteOrgTemplate;
}

export const updateOrgTemplateStatus = async(templateId: number, isActive: boolean) => {
  const response = await apolloClient.mutate({mutation : UPDATE_ORG_TEMPLATE_ACTIVE_STATUS, variables : { templateId, isActive}})
  return response.data.updateOrgTemplateStatus;
}

export const updateOrganizationTemplate = async ({
  organizationId,
  templateId,
  templateName,
}: {
  organizationId: string;
  templateId: number;
  templateName: string;
}) => {
  const response = await apolloClient.mutate({
    mutation: UPDATE_ORG_CONSENT_TEMPLATE,
    variables: { organizationId, templateId, templateName },
  });

  return response.data.updateOrganizationTemplate;
};

export const multiPatientOtherConsents = ({
  orgPatientConsentsFilter,
  limit,
  offset,
}: {
  orgPatientConsentsFilter: IOrgPatientConsentsFilter;
  limit: number;
  offset: number;
}) => {
  return apolloClient.query({
    query: MULTI_PATIENT_OTHER_CONSENTS,
    variables: { orgPatientConsentsFilter, limit, offset },
    fetchPolicy: 'network-only',
  }).then((response) =>{
    return response.data.listOrgPatientsConsent;
  })
};
