import { apolloClient } from "@/plugins/graphqlclient";
import { useUserStore } from "../store/modules/User";
import { ref } from "vue";
import { GET_USER_INFO } from "@/graphql/resources/UserRepository";
import { checkUserRoles } from "@/composables/SecurityService";
import { IUser } from "@/interfaces/user.interface";
import router from "@/router";
import hasAccess, { viewProgramInsightsRoles } from "../composables/roleAccess";
import { GET_ORG_BY_ID } from "@/graphql/resources/organization/Organization";
import { IOrgStore } from "@/interfaces/IOrganization";
import { orgStore } from "../store/modules/OrgStore";
import { ORG_CONSENT_FEATURE } from "@/enums/common.enum";

export const getLoggedInUserInfo = () => {
  const store = useUserStore();

  return {
    ...store.$state.user,
  };
};

export const patientCreated = ref("");
export const orgDetailsCreated = ref("");

export const getLoginDetails = async () => {
  const store = useUserStore();
  const response = await apolloClient.query({
    query: GET_USER_INFO,
    fetchPolicy: "network-only",
  });

  const {
    emailAddress,
    firstName,
    lastName,
    roles,
    userId,
    organizationId,
    organizationTimeZone,
  } = response.data.getUserInfo;

  const user = {
    emailAddress,
    firstName,
    lastName,
    roles,
    userId,
    organizationId,
    isLogged: true,
    organizationTimeZone,
  } as IUser;

  store.$state.user = user;
  checkUserRoles(roles);

  router.push({ name: getInitialRoute() });
  localStorage.setItem("loginUser", emailAddress);

  if (organizationId) {
    loadOrganizationById(organizationId);
  }
  return user;
};

const loadOrganizationById = async (organizationId: string) => {
  const store = orgStore();
  const { data } = await apolloClient.query({
    query: GET_ORG_BY_ID,
    variables: {
      orgId: organizationId,
    },
    fetchPolicy: "network-only",
  });

  const { orgId, name, npi, orgConfig } = data.getOrganizationById;

  const orgStoreInfo = {
    orgId,
    orgName: name,
    feature: orgConfig?.feature?.orgConsentTemplateFeature ?? ORG_CONSENT_FEATURE.DISABLED,
    npi,
  } as IOrgStore;

  store.$state.organization = orgStoreInfo;
};

const getInitialRoute = () =>
  hasAccess.viewDashBoard(viewProgramInsightsRoles)
    ? "dashboard"
    : "OrganizationList";
