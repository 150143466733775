<template>
  <v-card color="surfContainer pa-2">
    <v-card-title class="d-flex align-center text-primary bg-surface rounded-lg">
      <div class="d-flex align-center items-center text-primary">
        <v-icon size="large">mdi-file-sign</v-icon>
        <div class="d-flex flex-wrap">
          <span class="ml-4">Other Consents</span>
          <span class="v-card-subtitle flex-1-1-100">Get patient consents </span>
        </div>
      </div>
      <v-spacer />
      <div class="d-flex justify-end align-center items-center">
        <v-select v-model="slugId" :items="organizationConsents" label="Consents" width="300px" class="mx-4 mb-n5"
          variant="outlined"></v-select>
        <v-btn :disabled="!slugId" :rounded="false" elevation="3" @click="OpenConsentForm">Open Consent</v-btn>
      </div>
    </v-card-title>
    <v-card-item>
      <v-data-table :loading="isLoading" :items="patientSingedConsents" :headers="headers" item-key="name"
        items-per-page="5">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn id="viewPatient" color="primary" class="font-weight-bold" rounded variant="tonal" size="x-small"
          icon="mdi-eye-arrow-right-outline" elevation="3" @click="previewSignedConsents(item.submissionId)" />
          </template>
      </v-data-table>
    </v-card-item>
    <v-dialog class="mx-16" persistent v-model="isConsentFormOpen">
      <v-card>
        <v-row>
          <v-col class="pt-5" cols="12">
            <div class="text-right">
              <v-btn elevation="3" :icon="'mdi-close'" :loading="isLoading" @click="isConsentFormOpen = false"
                :rounded="false"></v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="pa-4">
            <v-skeleton-loader height="800" class="mx-12" v-if="isLoading" type="table-heading, image, table-tfoot"></v-skeleton-loader>
            <DocusealForm @load="() => {isLoading = false}"  :values="{ ...patientInformation }" :allow-to-resubmit="false" :with-send-copy-button="false"
              @complete="addPatientSignedConsent" :src="URL" :email="patientProfile.patientInfo?.email" :data-send-copy-email="false"
              custom-css=" #submit_form_button { background-color: #6750A4; color: white} .base-button { background-color: #6750A4; color: white}"
              />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog class="mx-16" persistent v-model="isPdfViewer">
      <patient-consent-pdf-viewer @close="isPdfViewer = false" :load-patient-signed-consent-url="loadPatientSignedConsentUrl" :submission-id="consentSubmissionId" />
    </v-dialog>
  </v-card>

  <v-snackbar color="error" class="text-white" v-model="showErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="success" class="text-white" v-model="showSuccessMessage" location="top right">
    {{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script lang="ts" setup>
import { IOrgConsentTemplate } from '@/interfaces/consentTemplate/orgConsentTemplate.interface';
import { useUserStore } from '@/store/modules/User';
import { PropType, onMounted, ref } from 'vue';
import { DocusealForm } from "@docuseal/vue";
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { IPatientSignedConsent, IPatientSingedConsentInput, ISignedConsentInfo } from '@/interfaces/consentTemplate/patientConsents.interface';
import moment from 'moment';
import { filter, split } from 'lodash';
import PatientConsentPdfViewer from './PatientConsentPdfViewer.vue';


const props = defineProps({
  loadOrgConsentTemplate: {
    type: Function as PropType<(orgId: string) => Promise<IOrgConsentTemplate[]>>,
    required: true,
  },
  loadPatientSignedConsents: {
    type: Function as PropType<(patientId: string) => Promise<IPatientSignedConsent[]>>,
    required: true,
  },
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  createPatientSignedConsent: {
    type: Function as PropType<(patientSingedConsentInput: IPatientSingedConsentInput) => Promise<boolean>>,
    required: true,
  },
  loadPatientSignedConsentUrl: {
    type: Function as PropType<(submissionId: number) => Promise<ISignedConsentInfo[]>>,
    required: true,
  }
});

const headers = ref([
  { title: 'Consent Name', value: 'templateName' },
  { title: 'Created At', value: 'createdAt' },
  { title: 'Updated At', value: 'updatedAt' },
  { title: 'Action', value: 'actions' }
]);

const organizationConsents = ref([] as { title: string; value: string }[]);
const { organizationId } = useUserStore().$state.user;
const isConsentFormOpen = ref(false);
const URL = ref('');
const slugId = ref('');
const isLoading = ref(false);
const patientId = ref('');
const patientSingedConsents = ref([] as IPatientSignedConsent[]);
const showErrorMessage = ref(false);
const errorMessage = ref('');
const showSuccessMessage = ref(false);
const successMessage = ref('');
const isPdfViewer = ref(false);
const consentSubmissionId = ref(0);

const patientInformation = ref({ name: '', dob: '', email: '' })

const fetchOrgConsents = () => {
  isLoading.value = true;
  props.loadOrgConsentTemplate(organizationId).then((response) => {
    const filteredConsents = filter(response, { isActive: true });
    organizationConsents.value = filteredConsents.map((orgConsent) => {
      return { title: orgConsent.templateName, value: orgConsent.slug }
    })
  })
}

const OpenConsentForm = () => {
  isLoading.value = true;
  isConsentFormOpen.value = true;
  URL.value = `https://docuseal.com/d/${slugId.value}`
}

const preparePatientFormData = () => {
  const { patientProfile: { patientInfo } } = props;
  patientInformation.value.name = `${patientInfo?.firstName} ${patientInfo?.lastName}`;
  patientInformation.value.email = patientInfo!.email!;
  patientInformation.value.dob = patientInfo!.dob;
  patientId.value = patientInfo?.patientId!;
}

const fetchPatientSignedDocuments = () => {
  props.loadPatientSignedConsents(patientId.value).then((response) => {
    patientSingedConsents.value = response.map((patientSingedConsent) => {
      return {
        ...patientSingedConsent,
        createdAt: moment(patientSingedConsent.createdAt).format('MM/DD/YY hh:mmA') as string,
        updatedAt: moment(patientSingedConsent.updatedAt).format('MM/DD/YY hh:mmA') as string,
      }
    })
  }).catch((error: Error) => {
    showErrorMessage.value = true;
    errorMessage.value = error.message;
  }).finally(() => isLoading.value = false)
}

const addPatientSignedConsent = (data: any) => {
  const { patientProfile: { patientInfo } } = props;
  const patientSingedConsentInput: IPatientSingedConsentInput = {
    patientId: patientId.value,
    patientFirstName: patientInfo!.firstName,
    patientLastName: patientInfo!.lastName,
    patientEmail: patientInfo!.email!,
    submissionId: data.submission_id,
    submissionSlug: split(data.submission_url, '/e/')[1],
    templateId: data.template.id,
    templateName: data.template.name,
    url: data.submission_url,
  }

  props.createPatientSignedConsent(patientSingedConsentInput).then(() => {
    showSuccessMessage.value = true;
    successMessage.value = 'Signed consent created successfully'
    fetchPatientSignedDocuments();
  }).catch((error: Error) => {
    showErrorMessage.value = true;
    errorMessage.value = error.message;
  });
};

const previewSignedConsents = (submissionId: number) => {
  consentSubmissionId.value = submissionId;
  isPdfViewer.value = true;
}

onMounted(() => {
  fetchOrgConsents();
  preparePatientFormData();
  fetchPatientSignedDocuments();
})
</script>
