<script setup lang="ts">
import { ref } from "vue";
import router from "../router";
import { useRoute } from "vue-router";
import { useUserStore } from "@/store/modules/User";
import { UserRoles } from "../enums/Roles";
import hasAccess, { viewOrgInventoryRoles, viewOrgIntegrationsRoles, viewOrgReportsRoles, viewAllOrgsRoles } from '../composables/roleAccess'

const route = useRoute();
const orgId = ref(route.params["orgId"]);
const userRoles = useUserStore().$state.user.roles.map((role) => {
  return role.role;
});

const navigateToOrgUsers = (name: string) => {
  router.push({
    name,
    params: {
      orgId: orgId.value,
    },
  });
};

const navigateToOrgProfile = () => {
  router.push({
    name: "org-profile-view",
    params: {
      orgId: orgId.value,
    },
  });
};

const navigateToOrgServiceProviders = () => {
  router.push({
    name: "org-service-providers",
    params: {
      orgId: orgId.value,
    },
  });
};

const navigateToVitalsConfigure = () => {
  router.push({
    name: "vital-configuration",
    params: {
      orgId: orgId.value,
    },
  });
};

const navigateToInventory = () => {
  router.push({
    name: "inventory",
    params: {
      orgId: orgId.value,
    },
  });
};

const navigateToOrgReports = () => {
  router.push({
    name: "org-reports",
    params: {
      orgId: orgId.value,
    },
  });
};

</script>

<template>
  <v-card class="org-profile-card" color="transparent">
    <v-layout class="layout-style">
      <v-navigation-drawer permanent :width="160" color="surface">
        <v-list nav class="text-primary" density="compact">
          <v-list-item-title class="text-left text-primary font-weight-black text-subtitle-1 d-flex items-center my-1">
            <v-icon size="small" class="ma-2">mdi-hospital-building</v-icon>Organization</v-list-item-title>
          <v-divider thickness="1" class="my-2" />
          <v-list-item class="item-style" :active="route.name === 'org-profile-view' ? true : false" title="Profile"
            value="profileConfig" @click="navigateToOrgProfile()"></v-list-item>

          <v-list-item class="item-style" :active="route.name === 'org-users' ? true : false" title="Users"
            value="users" @click="navigateToOrgUsers('org-users')"></v-list-item>

          <v-list-item v-if="hasAccess.viewAllOrganizations(viewAllOrgsRoles)" class="item-style"
            :active="route.name === 'org-consent-templates' ? true : false" title="Consents" value="users"
            @click="navigateToOrgUsers('org-consent-templates')"></v-list-item>

          <v-list-item v-if="hasAccess.viewOrgReports(viewOrgReportsRoles)" class="item-style"
            :active="route.name === 'org-reports'" title="Reports" value="orgReports"
            @click="navigateToOrgReports()"></v-list-item>
          <v-divider thickness="1" class="my-2" />
          <v-list-item-title v-if="hasAccess.viewOrgInventory(viewOrgInventoryRoles)"
            class="text-left my-1 text-primary font-weight-black text-subtitle-1 d-flex items-center"> <v-icon
              size="small" class="ma-2">mdi-devices</v-icon>Devices</v-list-item-title>
          <v-divider thickness="1" class="my-2" />
          <v-list-item v-if="hasAccess.viewOrgInventory(viewOrgInventoryRoles)" class="item-style" title="Inventory"
            value="inventory" @click="navigateToInventory()"
            :active="route.name === 'inventory' ? true : false"></v-list-item>
          <v-divider thickness="1" class="my-2" />
          <v-list-item-title class="text-left text-primary font-weight-black text-subtitle-1 my-1"><v-icon size="small"
              class="ma-2">mdi-office-building-cog</v-icon>Configuration</v-list-item-title>
          <v-divider thickness="1" class="my-2" />
          <v-list-item v-if="hasAccess.viewOrgIntegrations(viewOrgIntegrationsRoles)" class="item-style"
            title="Integrations" value="orgServiceProviderIntegration" @click="navigateToOrgServiceProviders()"
            :active="route.name === 'org-service-providers'"></v-list-item>

          <v-list-item class="item-style" title="Vitals" value="vitals" @click="navigateToVitalsConfigure()"
            :active="route.name === 'vital-configuration'"></v-list-item>

        </v-list>

      </v-navigation-drawer>
      <v-main>
        <div class="mb-5 d-flex justify-end pr-3" v-if="userRoles.includes(UserRoles.SYSTEM_ADMIN)">
          <router-link :to="{ name: 'OrganizationList' }">
            <v-btn style="width:230px" density="compact" icon class="text-none mt-2 ml-2 rounded-xl" size="large">
              Back To Organizations
            </v-btn>
          </router-link>
        </div>
        <router-view />
      </v-main>
    </v-layout>
  </v-card>
</template>

<style scoped>
.org-profile-card {
  width: 100%;
  height: 100%;
  box-shadow: none;
}

.layout-style {
  min-height: calc(100vh - var(--v-layout-top) - 5vh);
}


.item-title {
  font-size: 18px;
  font-weight: 900;
  color: #000000;
}
</style>
