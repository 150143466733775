<template>
  <v-card class="py-2" flat color="transparent">
    <v-row>
      <v-col cols="12">
        <EConsentListFilter @apply-filter="patientFilter" :removed-patient-name-value="removedPatientName"
          :is-no-subscription-tab="isNoSubscriptionTab" :removed-provider-name-value="removedProviderName"
          :program-list="programsList" :is-pending-tab="isPendingTab" :reset-filters="resetFilters" />
      </v-col>
      <v-col xs="12" md="10" sm="9" order-md="1" order-sm="1" class="d-flex justify-start">
        <FilterEconsentChips v-if="showChips" :filtered-patient-name-chip="filteredPatientNameChip"
          :filtered-provider-name-chip="filteredProviderNameChip" @close-patient-chip="closePatientChip"
          @close-provider-chip="closeProviderChip" />
      </v-col>
    </v-row>
    <v-skeleton-loader v-if="loading" color="surface" type="table"></v-skeleton-loader>
    <v-table v-else>
      <thead>
        <tr>
          <th v-for="column in headers" :key="column.title" scope="row" class="font-weight-bold">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!preparedPatientProgram.length">
          <td :colspan="headers.length" class="text-center">
            No data available
          </td>
        </tr>
        <template v-else v-for="(patientProgram, index) in preparedPatientProgram" :key="index">
          <tr>
            <td>
              {{ patientProgram.patient.firstName }}
              {{ patientProgram.patient.lastName }}
            </td>
            <td>{{ getPrimaryProviderName(patientProgram.patient.providers as IProvider[]) }}</td>
            <td>
              {{
                patientProgram.patient.createdTimestamp
                  ? moment
                    .unix(patientProgram.patient.createdTimestamp / 1000)
                    .format("MM-DD-YYYY")
                  : "N/A"
              }}
            </td>
            <td v-if="!isNoSubscriptionTab">
              <div>
                <v-chip v-for="(name, index) in getActiveProgramNames(patientProgram)" :key="index"
                  :style="{ ...getProgramStyle(name ?? ''), margin: '0 4px' }" size="small" density="comfortable">
                  {{ name }}
                </v-chip>
              </div>
            </td>
            <td v-if="programStatus === EPatientProgramStatus.PENDING">
              <div>
                <div v-if="getInactiveProgramNames(patientProgram)">
                  <v-chip v-for="(name, index) in getInactiveProgramNames(
                    patientProgram
                  )" :key="index" :style="{ ...getProgramStyle(name ?? ''), margin: '0 4px' }" size="small"
                    density="comfortable">
                    {{ name }}
                  </v-chip>
                </div>
                <div v-else>No pending consents</div>
              </div>
            </td>
            <td>{{ patientProgram.patient.email }}</td>
            <td>
              <router-link :to="{
                name: 'patientProfileWithInfo',
                params: {
                  patientId: patientProgram.patient.patientId,
                  patient: encodePatientInfo(patientProgram.patient),
                },
                query: {
                  name: 'PatientConsents'
                }
              }">
                <v-btn id="viewPatient" color="primary" class="font-weight-bold" rounded variant="tonal" size="x-small"
                  icon="mdi-account-eye" elevation="3" />
                <v-tooltip activator="parent" location="bottom">
                  Profile
                </v-tooltip>
              </router-link>

              <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                  <v-btn size="x-small" variant="tonal" elevation="3" rounded icon v-bind="props" @click="
                    patientProgram.patient.isExpanded =
                    !patientProgram.patient.isExpanded
                    " class="ml-5">
                    <v-icon>
                      {{ patientProgram.patient.isExpanded
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ patientProgram.patient.isExpanded
                  ? 'Collapse'
                  : 'Expand'
                  }}</span>
              </v-tooltip>
            </td>
          </tr>
          <transition name="v-table-nested">
            <tr v-if="patientProgram.patient.isExpanded">
              <td :colspan="headers.length">
                <v-card flat class="flex justify-center py-5">
                  <v-table :class="{ active: patientProgram.patient.isExpanded }" class="table-content">
                    <thead class="bg-surface">
                      <tr>
                        <th v-for="header in childHeaders" :key="header.key" scope="row" class="font-weight-bold">
                          {{ header.title }}
                        </th>
                      </tr>
                    </thead>
      <tbody>
        <tr v-for="program in patientProgram.patientProgramsConsents" :key="program.id">
          <td>
            {{ program.programName }}
          </td>
          <td>
            <v-checkbox v-model="program.isSubscribed" color="primary" hide-details
              :disabled="canModifyProgramSelection(program)" @update:model-value="
                handleProgramSelection(program, $event)
                "></v-checkbox>
          </td>
          <td>
            <v-chip :color="program.isActive && program.isSubscribed
              ? 'success'
              : 'error'
              " rounded text-color="white">
              {{
                program.isActive && program.isSubscribed
                  ? "Active"
                  : "Inactive"
              }}
            </v-chip>
          </td>
          <td>
            <div v-if="program.isDefault" class="text-caption">
              This program is consented by default
            </div>
            <div v-else>
              <v-btn v-if="hasConsentStatus(
                program,
                EConsentStatus.SIGNED
              ) && program.isSubscribed
              " rounded="false" elevation="3" class="mx-2" variant="tonal" @click="
                handleConsentDownload(
                  program.consent as IEConsent
                )
                ">Download Consent</v-btn>
              <v-btn v-if="hasConsentStatus(
                program,
                EConsentStatus.SIGNED
              ) && program.isSubscribed
              " rounded="false" elevation="3" class="mx-2" variant="tonal" @click="
                triggerResetConsent({ eConsentTemplateId: program.consent?.eConsentTemplateId as string, patientId: program.patientId as string })
                ">Reset Consent</v-btn>
              <v-btn v-else-if="hasConsentStatus(
                program,
                EConsentStatus.PENDING
              ) && program.isSubscribed
              " rounded="false" elevation="3" class="mx-2"
                @click="loadConsentTemplate(program, patientProgram.patient)">Get
                Consent</v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
  </td>
  </tr>
  </transition>
</template>
</tbody>
</v-table>
</v-card>
<div class="paginationContainer">
  <v-pagination v-model="currentPage" rounded="circle" :length="totalPages" :total-visible="$vuetify.display.mdAndUp
    ? totalVisiblePageNo
    : $vuetify.display.sm
      ? 6
      : 2
    " :density="'comfortable'" color="primary" @input="updateCurrentPage"
    class="my-4 px-5 custom-pagination"></v-pagination>
</div>

<v-dialog v-model="showResetConsentConfirmationDialog" persistent width="500px">
  <v-card min-height="200px">
    <v-card-title class="font-weight-bold bg-surface text-onSurfaceVar pl-5">Confirm</v-card-title>
    <v-card-text class="text-subtitle-1 word-wrap">
      {{ RESET_CONSENT_MESSAGE }}
    </v-card-text>
    <v-card-actions class="px-3 py-5">
      <v-row justify="end">
        <v-col cols="12" class="text-right">
          <v-btn color="primary" class="text-white mx-2" variant="tonal" elevation="3" rounded="false"
            @click="showResetConsentConfirmationDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" class="text-white mx-2" variant="flat" elevation="3" rounded="false"
            @click="resetPatientProgramEConsent">
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog v-model="showConfirmationDialog" persistent width="500px">
  <v-card min-height="200px">
    <v-card-title class="font-weight-bold bg-surface text-onSurfaceVar pl-5">Confirm</v-card-title>
    <v-card-text class="text-subtitle-1 word-wrap">
      {{ isProgramSubscribed ? subscribeMessage : unsubscribeMessage }}
      <v-list class="text-subtitle-1 font-weight-bold text-primary">
        <v-list-item :key=idx class="pa-0" v-for="(program, idx) in curSelectedPrograms">
          {{ `${idx + 1}. ${program.programName}` }}
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions class="px-3 py-5">
      <v-row justify="end">
        <v-col cols="12" class="text-right">
          <v-btn color="primary" class="text-white mx-2" variant="tonal" elevation="3" rounded="false"
            @click="resetSelectedPrograms()">
            Cancel
          </v-btn>
          <v-btn color="primary" class="text-white mx-2" variant="flat" elevation="3" rounded="false"
            @click="handleProgramSubscription()">
            {{ isProgramSubscribed ? 'Subscribe' : 'Unsubscribe' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog fullscreen class="template-viewer-dialog" persistent v-model="showTemplate">
  <e-consent-template-viewer @close-template-viewer="closeTemplateViewer()" :patient-profile="patientProfileProps"
    :econsent-template-id="consentingTemplateId" :template-params="templateParams"
    :get-consent-upload-url="getConsentUploadUrl" @reload-patient-program-consents="handleConsentSigned()"
    :sign-patient-program-consent="signPatientProgramConsent" :selected-program-id="(selectedProgramId as string)"
    :data-loader="consentTemplateLoader"></e-consent-template-viewer>
</v-dialog>
<v-snackbar color="error" class="text-white" v-model="showEconsentOperationFailedErr" location="top right">
  {{ errorMessage }}
  <template v-slot:actions>
    <v-icon class="ml-3" @click="showEconsentOperationFailedErr = false">mdi-close</v-icon>
  </template>
</v-snackbar>
</template>

<script setup lang="ts">
import {
  EConsentAcknowledgement,
  EConsentStatus,
  EPatientProgramStatus,
} from "@/enums/patient-program.enum";
import {
  IEConsentTemplate,
  TemplateParams,
} from "@/interfaces/econsent-template.interface";
import {
  IEConsent,
  IPatientConsents,
  IPatientProgram,
  IPatientProgramConsent,
  IPatientProgramConsents,
  IProgram,
} from "@/interfaces/econsent.interface";
import {
  filter,
  find,
  first,
  isEmpty,
  map,
  forEach,
  pick,
  assign,
  set,
  head,
} from "lodash";
import { onMounted, PropType, ref, watch, computed } from "vue";
import EConsentTemplateViewer from "./EConsentTemplateViewer.vue";
import { IPatientInfo, IPatientProfileProps } from "@/interfaces/patient.interface";
import useConsentDownload from "@/composables/eConsentUtility";
import EConsentListFilter from "./EConsentListFilter.vue";
import FilterEconsentChips from "./FilterEConsentChips.vue";
import moment from "moment";
import { IProvider } from '../../interfaces/patient.interface';
import { encodePatientInfo } from '../../composables/patient/view-utility.composable';
import { multiSubscriptionEventBusKey } from "../../events/bus-keys/consents-subscription-event.bus-keys";
import { useEventBus } from "@vueuse/core";

const props = defineProps({
  subscribePrograms: {
    type: Function as PropType<
      (patientId: string, programIds: string[]) => Promise<IPatientProgram[]>
    >,
    required: true,
  },
  consentTemplateLoader: {
    type: Function as PropType<
      (
        econsentTemplateId: string,
        templateParamsInput: TemplateParams
      ) => Promise<IEConsentTemplate>
    >,
    required: true,
  },
  getConsentUploadUrl: {
    type: Function as PropType<
      (patientId: string, fileName: string) => Promise<string>
    >,
    required: true,
  },
  signPatientProgramConsent: {
    type: Function as PropType<
      (
        patientId: string,
        eConsentTemplateId: string,
        eConsentFileName: string,
        selectedProgramId: string,
        acknowledgment: EConsentAcknowledgement
      ) => Promise<IPatientProgram[]>
    >,
    required: true,
  },
  unSubscribeProgram: {
    type: Function as PropType<
      (patientId: string, programId: string) => Promise<void>
    >,
    required: true,
  },

  programStatus: {
    type: String as PropType<EPatientProgramStatus>,
    required: true,
  },

  getAllPatientConsents: {
    type: Function as PropType<
      (
        limit: number,
        offset: number,
        filter: {
          patientName: string;
          providerName: string;
          programStatus: EPatientProgramStatus;
          activeSubscribedPrograms: string[];
          pendingProgramConsents: string[];
          activeStatus: boolean[];
          fromDate: string;
          toDate: string;
        }
      ) => Promise<IPatientProgramConsents>
    >,
    required: true,
  },

  getAllPrograms: {
    type: Function as PropType<() => Promise<IProgram[]>>,
    required: true,
  },

  isPendingTab: {
    type: Boolean,
    required: true,
  },

  isNoSubscriptionTab: {
    type: Boolean,
    required: true,
  },

  isConsentSigned: {
    type: Boolean,
    default: false,
    required: true,
  },

  isConsentReset: {
    type: Boolean,
    default: false,
    required: true,
  }
});

const {
  consentTemplateLoader,
  subscribePrograms,
  signPatientProgramConsent,
  unSubscribeProgram,
  getAllPatientConsents,
  getAllPrograms,
  programStatus,
  isConsentSigned,
  isConsentReset
} = props;

const patientConsents = ref<IPatientProgramConsents>();
const loading = ref(false);
const selectedPrograms = ref(new Map());
const showConfirmationDialog = ref(false);
const curSelectedPrograms = ref([] as IPatientProgram[]);
const isProgramSubscribed = ref(false);
const consentingTemplateId = ref("");
const showTemplate = ref(false);
const patientProfileProps = ref<IPatientProfileProps>({ patientId: "" });
const unsubscribeMessage = ref("");
const subscribeMessage = ref("");
const templateParams = ref();
const preparedPatientProgram = ref([] as IPatientConsents[]);
const patientId = ref("");
const programId = ref("");
const { errorMessage, showEconsentOperationFailedErr, handleConsentDownload, handleResetConsent } =
  useConsentDownload(loading);
const modifiedPatientConsents = ref(
  {} as Record<string, IPatientProgramConsent[]>
);
const itemsPerPage = ref(Number(import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO) || 10);
const currentPage = ref(1);
const filteredPatientNameChip = ref("");
const filteredProviderNameChip = ref("");
const filteredFromDate = ref("");
const filteredToDate = ref("");
const removedPatientName = ref("");
const removedProviderName = ref("");
const showChips = ref(false);
const totalCount = ref(0);
const totalVisiblePageNo = import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO;
const selectedProgramId = ref(null as null | string);
const pendingProgramConsents = ref<string[]>();
const activeSubscribedPrograms = ref<string[]>();
const programsList = ref<IProgram[]>([]);
const activeStatus = ref<boolean[]>();
const resetFilters = ref({
  resetPatientFilter: false,
  resetProviderFilter: false,
});
const showResetConsentConfirmationDialog = ref(false);
let selectedTemplateToReset = null as null | string;
let selectedPatientIdToReset = null as null | string;
const RESET_CONSENT_MESSAGE = `Are you sure you want to reset the consent?`;
const emit = defineEmits(['consent-signed', 'consent-reset', 'consent-signed-triggered', 'consent-reset-triggered']);
const multiSubscriptionEventBus = useEventBus(multiSubscriptionEventBusKey);

watch(
  [() => props.isConsentSigned, () => props.isConsentReset],
  async ([isConsentSigned, isConsentReset]) => {
    if (isConsentSigned || isConsentReset) {
      await getAllPatientConsentsList();
      if (isConsentSigned === true) {
        emit('consent-signed-triggered');
      }
      if (isConsentReset === true) {
        emit('consent-reset-triggered');
      }
    }
  }
);

const headers = [
  { title: "Patient Name" },
  { title: "Primary Provider" },
  { title: "Enrolled Date" },
  ...(programStatus !== EPatientProgramStatus.NO_SUBSCRIPTION
    ? [{ title: "Subscribed Programs" }]
    : []),
  ...(programStatus === EPatientProgramStatus.PENDING
    ? [{ title: "Pending Consents" }]
    : []),
  { title: "Email" },
  { title: "Action" },
];

const childHeaders = [
  {
    title: "Program Name",
    key: "programName",
    align: "start",
  },
  { title: "Subscription", key: "isSubscribed" },
  { title: "Active Status", key: "isActive" },
  { title: "Action", key: "action" },
];

onMounted(async () => {
  await getAllPatientConsentsList();
  multiSubscriptionEventBus.on(async () => {
    await getAllPatientConsentsList();
  });
});

const getPrimaryProviderName = (providers: IProvider[]): string => {
  const primaryProvider = head(filter(providers, (provider) => { return provider.isPrimaryProvider }));
  if (primaryProvider) {
    return `${primaryProvider.firstName} ${primaryProvider.lastName}`;
  }

  return 'N/A';
}

const getActiveProgramNames = (patientProgram: IPatientConsents) =>
  patientProgram.patientProgramsConsents
    .filter((program) => program.isActive && program.isSubscribed)
    .map((program) => program.shortCode);

const getInactiveProgramNames = (patientProgram: IPatientConsents) =>
  patientProgram.patientProgramsConsents
    .filter((program) => !program.isActive && program.isSubscribed)
    .map((program) => program.shortCode);

const getProgramStyle = (programName: string) => {
  const programStyles: { [key: string]: { color: string } } = {
    RPM: { color: "darkorange" },
    CCM: { color: "darkgreen" },
    CCCM: { color: "darkblue" },
    PCM: { color: "Grey" },
  };
  return programStyles[programName] || {};
};

const totalPages = computed(() =>
  Math.ceil(totalCount.value / itemsPerPage.value)
);

const updateCurrentPage = (page: number) => {
  currentPage.value = page;
};

const patientFilter = (filteredPatientName: {
  patientName: string;
  providerName: string;
  pendingProgramConsents: string[];
  activeSubscribedPrograms: string[];
  activeStatus: boolean[];
  fromDate: string;
  toDate: string;
}) => {
  filteredPatientNameChip.value = filteredPatientName.patientName;
  filteredProviderNameChip.value = filteredPatientName.providerName;
  pendingProgramConsents.value = filteredPatientName.pendingProgramConsents;
  activeSubscribedPrograms.value = filteredPatientName.activeSubscribedPrograms;
  activeStatus.value = filteredPatientName.activeStatus;
  filteredToDate.value = filteredPatientName.toDate;
  filteredFromDate.value = filteredPatientName.fromDate;
  showChips.value = true;
  currentPage.value = 1;
  getAllPatientConsentsList();
};

const closePatientChip = (closeChip: string) => {
  filteredPatientNameChip.value = closeChip;
  removedPatientName.value = closeChip;
  resetFilters.value.resetPatientFilter = true;
  getAllPatientConsentsList();
};

const closeProviderChip = (closeChip: string) => {
  filteredProviderNameChip.value = closeChip;
  removedProviderName.value = closeChip;
  resetFilters.value.resetProviderFilter = true;
  getAllPatientConsentsList();
};

const triggerResetConsent = ({ eConsentTemplateId, patientId }: { eConsentTemplateId: string, patientId: string }) => {
  showResetConsentConfirmationDialog.value = true;
  selectedTemplateToReset = eConsentTemplateId;
  selectedPatientIdToReset = patientId;

}

const resetPatientProgramEConsent = async () => {
  showResetConsentConfirmationDialog.value = false;
  await handleResetConsent({ patientId: selectedPatientIdToReset as string, eConsentTemplateId: selectedTemplateToReset as string });
  await getAllPatientConsentsList();
  emit('consent-reset');
}

const getAllPatientConsentsList = async () => {
  loading.value = true;
  programsList.value = await getAllPrograms();
  patientConsents.value = await getAllPatientConsents(
    itemsPerPage.value,
    itemsPerPage.value * (currentPage.value - 1),
    {
      patientName: filteredPatientNameChip.value,
      providerName: filteredProviderNameChip.value,
      programStatus,
      activeSubscribedPrograms: activeSubscribedPrograms.value as string[],
      pendingProgramConsents: pendingProgramConsents.value as string[],
      activeStatus: activeStatus.value as boolean[],
      fromDate: filteredFromDate.value,
      toDate: filteredToDate.value,
    }
  );
  totalCount.value = patientConsents.value?.count as number;
  const resultPatientConsents: IPatientConsents[] = patientConsents.value
    ?.patientConsents as IPatientConsents[];
  preparedPatientProgram.value = resultPatientConsents
    .filter((patientConsent) => {
      return (
        patientConsent.patient &&
        patientConsent.patient.firstName &&
        patientConsent.patient.lastName &&
        patientConsent.patient.patientId
      );
    })
    .map((patientConsent) => {
      patientConsent.patient.isExpanded = false;

      patientConsent.patientProgramsConsents.forEach((patient) => {
        assign(
          patient,
          pick(patientConsent.patient, ["firstName", "lastName", "patientId"])
        );
      });

      return patientConsent;
    });
  const preparedData = preparedProgramConsents(resultPatientConsents);

  const selectedPatientPrograms = filter(
    preparedData.map((programConsent) => programConsent as IPatientProgram),
    (program) => !!program.patientProgramId && program.isSubscribed
  );

  modifiedPatientConsents.value =
    patientConsents.value?.patientConsents.reduce((acc, entry) => {
      const {
        patientProgramsConsents,
        patient: { firstName, lastName, patientId },
      } = entry;
      acc[patientId] = patientProgramsConsents.map((programConsent) => ({
        ...programConsent,
        firstName,
        lastName,
        patientId,
      }));
      return acc;
    }, {} as Record<string, IPatientProgramConsent[]>) || {};

  const selectedProgramsMap = selectedPatientPrograms.reduce((map, program) => {
    if (program.patientId) {
      map.set(program.patientId, [
        ...(map.get(program.patientId) || []),
        program,
      ]);
    }
    return map;
  }, new Map());

  selectedPrograms.value = selectedProgramsMap;
  loading.value = false;
  resetFilters.value.resetPatientFilter = false;
  resetFilters.value.resetProviderFilter = false;
};

watch(currentPage, async () => {
  getAllPatientConsentsList();
});

const preparedProgramConsents = (
  preparedPatientProgram: IPatientConsents[]
): IPatientProgramConsent[] => {
  const preparedProgram: IPatientProgramConsent[] =
    preparedPatientProgram.flatMap((patient) =>
      patient.patientProgramsConsents.map((consent) => ({
        isExpanded: false,
        ...consent,
      }))
    );
  return preparedProgram;
};

const canModifyProgramSelection = (patientProgram: IPatientProgramConsent) => {
  const filteredPrograms = selectedPrograms.value.get(patientProgram.patientId);
  if (filteredPrograms) {
    const childPrograms = filteredPrograms.filter(
      (program: IPatientProgramConsent) => {
        return program.parentProgramId === patientProgram.programId;
      }
    );
    return patientProgram.isDefault || !isEmpty(childPrograms);
  }
};

const handleProgramSelection = (
  program: IPatientProgramConsent,
  isProgramSelected: boolean | null
) => {
  const filteredPrograms = selectedPrograms.value.get(program.patientId);
  const { firstName, lastName } = program;
  unsubscribeMessage.value = `Are you sure you want to unsubscribe the ${firstName} ${lastName} from the following program(s)?`;
  subscribeMessage.value = `Are you sure you want to subscribe the patient ${firstName} ${lastName} to the following program(s)?`;
  curSelectedPrograms.value = [];

  if (isProgramSelected) {
    curSelectedPrograms.value.push(program as IPatientProgram);
    handleParentProgramSelection(
      program.parentProgramId,
      filteredPrograms,
      curSelectedPrograms.value,
      program.patientId as string
    );
    showConfirmationDialog.value = true;
    isProgramSubscribed.value = true;
  } else {
    curSelectedPrograms.value.push(program as IPatientProgram);
    showConfirmationDialog.value = true;
    isProgramSubscribed.value = false;
  }
  patientId.value = program.patientId as string;
  programId.value = program.id as string;
};

const handleParentProgramSelection = (
  parentProgramId: string | undefined,
  selectedPrograms: IPatientProgram[],
  curSelectedPrograms: IPatientProgram[],
  patientId: string
) => {
  const parentProgram = find(modifiedPatientConsents.value[patientId], {
    programId: parentProgramId,
  });

  if (parentProgram) {
    const isParentProgramSelected = !isEmpty(
      filter(
        selectedPrograms,
        (selectedProgram) =>
          selectedProgram.programId === parentProgram.programId
      )
    ) || [];

    curSelectedPrograms.push(parentProgram as IPatientProgram);

    if (!isParentProgramSelected) {
      selectedPrograms = [
        ...selectedPrograms,
        parentProgram as IPatientProgram,
      ];
    }
  }
};

const hasConsentStatus = (
  patientProgram: IPatientProgramConsent,
  consentStatus: EConsentStatus
) => {
  return (
    (patientProgram?.patientProgramId &&
      patientProgram?.consent?.status === consentStatus.toString()) ??
    false
  );
};

const loadConsentTemplate = async (
  patientProgramConsent: IPatientProgramConsent,
  patient: IPatientInfo
) => {
  selectedProgramId.value = patientProgramConsent.programId;
  const { firstName, lastName } = patientProgramConsent;
  const patientId: string = patientProgramConsent.patientId as string;
  const patientInfos: IPatientProfileProps = {
    patientId,
    patientInfo: patient
  };
  patientProfileProps.value = patientInfos;
  templateParams.value = {
    patientName: `${firstName} ${lastName}`,
    date: moment().format('MM/DD/YYYY'),
    dob: patient.dob,
  } as TemplateParams;
  consentingTemplateId.value = patientProgramConsent.consent
    ?.eConsentTemplateId as string;
  showTemplate.value = true;
};

const resetSelectedPrograms = () => {
  showConfirmationDialog.value = false;
  const isSubscribedValue = isProgramSubscribed.value;

  forEach(preparedPatientProgram.value, (patientProgram) => {
    if (patientProgram.patient.patientId === patientId.value) {
      const program = find(patientProgram.patientProgramsConsents, {
        id: programId.value,
      });

      if (program) program.isSubscribed = isSubscribedValue ? false : true;
    }
  });

};

const handleProgramSubscription = async () => {
  showConfirmationDialog.value = false;
  loading.value = true;
  const patientId = curSelectedPrograms.value[0].patientId;
  const programIds = map(curSelectedPrograms.value, "programId");

  if (!isEmpty(programIds) && first(programIds)) {
    isProgramSubscribed.value
      ? await subscribePrograms(patientId as string, programIds)
      : await unSubscribeProgram(
        patientId as string,
        first(programIds) as string
      );
    await getAllPatientConsentsList();
    curSelectedPrograms.value = [];
    loading.value = false;
  }
  const patientProgram = preparedPatientProgram.value.find(
    (patientProgram) => patientProgram.patient.patientId === patientId
  );

  if (isProgramSubscribed.value) {
    emit('consent-reset');
  }

  if (patientProgram) {
    set(patientProgram, "patient.isExpanded", true);
  }
};

const closeTemplateViewer = () => {
  showTemplate.value = false;
};

const handleConsentSigned = () => {
  showTemplate.value = false;
  getAllPatientConsentsList();
  emit('consent-signed');
};

defineExpose({
  currentPage,
  totalPages,
  updateCurrentPage,
});
</script>

<style scoped>
.table-content {
  inline-size: 1000px;
  border: 1px solid #ccc;
  /* Set the border color and style */
}
</style>
