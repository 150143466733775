import { IOrgStore } from "@/interfaces/IOrganization";
import { defineStore } from "pinia";

export const orgStore = defineStore("organization", {
  state: () => ({
    organization: {} as IOrgStore
  }),
  getters:{
    getOrganization: (state) => state.organization
  },
  actions: {
    saveOrganization(organization: IOrgStore) {
        this.organization = organization;
    }
  },
  persist:{
    storage: sessionStorage,
  }
});
