import { CREATE_PATIENT_SIGNED_CONSENT, PATIENT_SIGNED_CONSENT_URL, PATIENT_SIGNED_CONSENTS } from "@/graphql/resources/consentTemplate/patientSignedConsentRepository";
import { IPatientSingedConsentInput } from "@/interfaces/consentTemplate/patientConsents.interface";
import { apolloClient } from "@/plugins/graphqlclient";

export const createPatientSignedConsent = async (
  patientSingedConsentInput: IPatientSingedConsentInput
) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_PATIENT_SIGNED_CONSENT,
    variables: { patientSingedConsentInput },
  });

  return response.data;
};

export const fetchPatientSignedConsents = async (patientId: string) => {
  const response = await apolloClient.query({
    query: PATIENT_SIGNED_CONSENTS,
    variables: { patientId },
    fetchPolicy: "network-only",
  });

  return response.data.patientSignedConsents;
};

export const loadPatientSignedConsentUrl = async (submissionId: number) => {
  const response = await apolloClient.query({
    query: PATIENT_SIGNED_CONSENT_URL,
    variables: { submissionId },
    fetchPolicy: "cache-first",
  });

  return response.data.signedSubmissionUrlById;
}
