<template>
  <v-card class="h-100 py-2" color="surfContainer" elevation="2">
    <v-card-title>
      <v-toolbar density="compact" color="surface" class="pa-2">
        <v-toolbar-title class="text-primary pa-2">
          <div class="d-flex align-center items-center text-primary">
            <v-icon size="large">mdi-file-sign </v-icon>
            <div class="d-flex flex-wrap">
              <span class="ml-4">Other Consents</span>
              <span class="v-card-subtitle flex-1-1-100">
                Manage patients' consents</span>
            </div>
          </div>
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-item>
      <div>
        <v-row>
          <v-col cols="2" class="mt-3 mb-n3"> <v-text-field v-model="filter.patientName" label="Patient Name"
              variant="solo" elevation="3" density="compact" color="primary" hide-detail append-inner-icon="mdi-magnify"
              @keyup.enter="loadItems">
            </v-text-field></v-col>
        </v-row>
      </div>
    </v-card-item>
    <v-card-item>
      <v-data-table-server :loading="loading" class="px-4" v-model:items-per-page="itemsPerPage" :headers="headers"
        :items="serverItems" :items-length="totalItems" item-value="email" :show-expand="true"
        @update:options="loadItems" v-model:expanded="expanded">
        <template v-slot:[`item.isActive`]="{ item }">
          <v-chip :color="item.isActive ? 'success' : 'error'" :rounded="false">
            {{ item.isActive ? "Active" : "Inactive" }}
          </v-chip>
        </template>
        <template v-slot:expanded-row="{ item }">
          <td class="py-5 bg-surfContainer " colspan="12">
            <div class="px-16">
              <v-data-table :hide-default-footer="true" :items="item.consent" :items-per-page="-1"
                :headers="innerTableHeaders">
                <template v-slot:[`item.actions`]="{ item: consentItem }">
                  <span v-if="consentItem.submissionId">
                    <v-btn id="viewPatient" color="primary" class="font-weight-bold" rounded variant="tonal"
                      size="x-small" icon="mdi-eye-arrow-right-outline" elevation="3"
                      @click="previewSignedConsents(consentItem.submissionId)" />
                  </span>
                  <span v-else>
                    <v-btn @click="openSignedConsentForm(item, consentItem)" :rounded="false" density="compact" small color="primary">Get Consent</v-btn>
                  </span>
                </template>
              </v-data-table>
            </div>
          </td>
        </template>
      </v-data-table-server>
    </v-card-item>
    <v-dialog class="mx-16" persistent v-model="isPdfViewer">
      <patient-consent-pdf-viewer @close="isPdfViewer = false"
        :load-patient-signed-consent-url="loadPatientSignedConsentUrl" :submission-id="consentSubmissionId" />
    </v-dialog>
    <v-dialog class="mx-16" persistent v-model="isConsentFormOpen">
      <v-card>
        <v-row>
          <v-col class="pt-5" cols="12">
            <div class="text-right">
              <v-btn elevation="3" :icon="'mdi-close'" :loading="loading" @click="isConsentFormOpen = false"
                :rounded="false"></v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="pa-4">
            <v-skeleton-loader height="800" class="mx-12" v-if="isLoading" type="table-heading, image, table-tfoot"></v-skeleton-loader>
            <DocusealForm @load="() => {isLoading = false}" :values="{ ...patientInformation }" :allow-to-resubmit="true" :with-send-copy-button="false"
              @complete="addPatientSignedConsent" :src="URL" :email="patientInfo.email" :data-send-copy-email="false"
              custom-css=" #submit_form_button { background-color: #6750A4; color: white} .base-button { background-color: #6750A4; color: white}" />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
  <v-snackbar color="error" class="text-white" v-model="showErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="success" class="text-white" v-model="showSuccessMessage" location="top right">
    {{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script setup lang="ts">
import { IOrgPatientConsentsFilter, MultiPatientConsent, OrgPatientConsentBatch } from "@/interfaces/consentTemplate/orgConsentTemplate.interface";
import { IPatientSingedConsentInput, ISignedConsentInfo } from "@/interfaces/consentTemplate/patientConsents.interface";
import { PropType, reactive, ref } from "vue";
import PatientConsentPdfViewer from '../patient-consents/PatientConsentPdfViewer.vue';
import { split } from "lodash";
import { DocusealForm } from "@docuseal/vue";

const props = defineProps({
  multiPatientOtherConsents: {
    type: Function as PropType<(orgPatientConsentsFilter: IOrgPatientConsentsFilter, limit: number, offset: number) => Promise<OrgPatientConsentBatch>>,
    required: true,
  },
  loadPatientSignedConsentUrl: {
    type: Function as PropType<(submissionId: number) => Promise<ISignedConsentInfo[]>>,
    required: true,
  },
  createPatientSignedConsent: {
    type: Function as PropType<(patientSingedConsentInput: IPatientSingedConsentInput) => Promise<boolean>>,
    required: true,
  },
})

const headers = ref([
  { title: "Name", value: "patientName" },
  { title: "Email", value: "email" },
  { title: "DOB", value: "dob" },
  { title: "Status", value: "isActive" },
]);
const innerTableHeaders = ref([
  { title: "Consent Name", value: "templateName" },
  { title: "Actions", value: "actions" },
])

const itemsPerPage = ref(10);
const expanded = ref([]);
const serverItems = ref([] as MultiPatientConsent[]);
const totalItems = ref(0);
const consentSubmissionId = ref(0);
const page = ref(0);
const URL = ref('');

const loading = ref(false);
const isPdfViewer = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('');
const showSuccessMessage = ref(false);
const successMessage = ref('');
const isConsentFormOpen = ref(false);
const isLoading = ref(false);

const patientInfo = reactive({
  patientId: '',
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
});
const patientInformation = ref({ name: '', dob: '', email: '' });

const filter = ref({ patientName: '' } as IOrgPatientConsentsFilter)

const loadItems = (data?: any) => {
  page.value = data ? data.page : 1;
  loading.value = true;
  props.multiPatientOtherConsents(filter.value, itemsPerPage.value === -1 ? totalItems.value : itemsPerPage.value, (page.value - 1) * itemsPerPage.value).then((response) => {
    totalItems.value = response.count;
    serverItems.value = response.orgPatientConsent.map((patient) => {
      return { ...patient, patientName: `${patient.firstName} ${patient.lastName}` }
    });
  }).catch((error: Error) => {
    showErrorMessage.value = true;
    errorMessage.value = error.message;
  })
    .finally(() => { loading.value = false })
};

const previewSignedConsents = (submissionId: number) => {
  isPdfViewer.value = true;
  consentSubmissionId.value = submissionId;
}

const preparePatientFormData = () => {
  patientInformation.value.name = `${patientInfo.firstName} ${patientInfo?.lastName}`;
  patientInformation.value.email = patientInfo!.email!;
  patientInformation.value.dob = patientInfo!.dob;
}

const openSignedConsentForm = (data: MultiPatientConsent, consent: unknown) => {
  isLoading.value = true;
  const { firstName, lastName, email, patientId, dob, } = data;
  patientInfo.patientId = patientId;
  patientInfo.firstName = firstName;
  patientInfo.lastName = lastName;
  patientInfo.email = email;
  patientInfo.dob = dob;
  preparePatientFormData();
  isConsentFormOpen.value = true;
  URL.value = `https://docuseal.com/d/${(consent as { slug: number }).slug}`
}

const addPatientSignedConsent = (data: any) => {

  const patientSingedConsentInput: IPatientSingedConsentInput = {
    patientId: patientInfo.patientId,
    patientFirstName: patientInfo.firstName,
    patientLastName: patientInfo.lastName,
    patientEmail: patientInfo.email,
    submissionId: data.submission_id,
    submissionSlug: split(data.submission_url, '/e/')[1],
    templateId: data.template.id,
    templateName: data.template.name,
    url: data.submission_url,
  }

  props.createPatientSignedConsent(patientSingedConsentInput).then(() => {
    showSuccessMessage.value = true;
    successMessage.value = 'Signed consent created successfully'
    loadItems();
  }).catch((error: Error) => {
    showErrorMessage.value = true;
    errorMessage.value = error.message;
  });
};

</script>
