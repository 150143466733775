<script setup lang="ts">
import router from "../router";
import { useUserStore } from "@/store/modules/User";
import hasAccess, {
  viewProgramInsightsRoles,
  viewAllOrgsRoles,
  viewOrgRoles,
  viewPatientsRoles,
  viewTaskBoardRoles,
  viewMultiPatientConsentsRoles,
  viewOrgReportsRoles
} from "../composables/roleAccess";
import { onMounted, ref, watch } from "vue";
import { useDisplay } from "vuetify";
import { useRoute } from "vue-router";
import { orgStore } from "@/store/modules/OrgStore";
import { ORG_CONSENT_FEATURE } from '@/enums/common.enum';

const routeTo = (routeName: string) => {
  router.push({ name: routeName });
};

let drawer = ref(true as boolean);
const { mdAndUp } = useDisplay();
const navOnHover = ref(false as boolean);
const organizationId = useUserStore().$state.user.organizationId;
const route = useRoute();
const activeRouteName = ref('');
const orgFeature = ref('');

const dashboardRoutes = ["dashboard"];
const insightsRoutes = ["program-insights", "program-insights-focused"];
const taskBoardRoutes = ["task-board"];
const patientRoutes = [
  "ListPatient",
  "patientProfileWithInfo",
  "CreatePatientStepper",
];
const organizationRoutes = [
  "org-profile-view",
  "OrganizationList",
  "vital-configuration",
  "createOrg",
  "createOrgUser",
  "org-users",
  "inventory",
  "org-service-providers",
];

const organizationReportsRoutes = ["org-reports"];

const consentsRoutes = ["PatientConsents"];

const openOrgProfile = () => {
  router.push({
    name: "org-profile-view",
    params: {
      orgId: organizationId,
    },
  });
};

const toggleDrawer = () => {
  if (mdAndUp.value) {
    drawer.value = !drawer.value;
  }
  navOnHover.value = !navOnHover.value;
};

const navigateToOrgReports = () => {
  router.push({
    name: "org-reports",
    params: {
      orgId: organizationId,
    }
  })
}

watch(() => orgStore().$state.organization.feature, (newValue) => {
  orgFeature.value = newValue;
}, { deep: true, immediate: true })

watch(() => route.name, () => {
  activeRouteName.value = route.name as string;
})

onMounted(() => {
  activeRouteName.value = route.name as string;
})
</script>

<template>
  <div>
    <v-navigation-drawer color="surface" :rail="drawer" :elevation="0" permanent width="200"
      :expand-on-hover="$vuetify.display.smAndDown && navOnHover">
      <div class="my-4">
        <slot name="menu-list">
          <v-list density="compact" nav>
            <v-list-item prepend-icon="mdi-cube-scan" :active="dashboardRoutes.includes(activeRouteName)"
              class="text-primary text-left" @click="routeTo('dashboard')" title="Dashboard" value="dashboard"
              v-if="hasAccess.viewDashBoard(viewProgramInsightsRoles)">
              <v-tooltip activator="parent">Dashboard</v-tooltip>
            </v-list-item>

            <v-list-item prepend-icon="mdi-set-center-right" :active="insightsRoutes.includes(activeRouteName)"
              class="text-primary text-left" v-if="hasAccess.viewDashBoard(viewProgramInsightsRoles)"
              @click="routeTo('program-insights')" title="Program Insights" value="program-insights">
              <v-tooltip activator="parent">Program Insights</v-tooltip>
            </v-list-item>


            <v-list-item prepend-icon="mdi-checkbox-marked-circle-auto-outline"
              :active="taskBoardRoutes.includes(activeRouteName)" class="text-primary text-left"
              v-if="hasAccess.viewTaskBoard(viewTaskBoardRoles)" @click="routeTo('task-board')" title="Task Board"
              value="task-board">
              <v-tooltip activator="parent">Task Board</v-tooltip>
            </v-list-item>

            <v-list-item prepend-icon="mdi-domain" :active="organizationRoutes.includes(activeRouteName)"
              class="text-primary text-left" v-if="hasAccess.viewAllOrganizations(viewAllOrgsRoles)"
              @click="routeTo('OrganizationList')" title="Organizations" value="org">
              <v-tooltip activator="parent">Organizations</v-tooltip>
            </v-list-item>

            <v-list-item prepend-icon="mdi-crowd" :active="patientRoutes.includes(activeRouteName)"
              class="text-primary text-left" title="Patients" v-if="hasAccess.viewPatients(viewPatientsRoles)"
              @click="routeTo('ListPatient')" value="orgPatient">
              <v-tooltip activator="parent">Patients</v-tooltip>
            </v-list-item>
            <v-list-item prepend-icon="mdi-shape-plus" :active="consentsRoutes.includes(activeRouteName)"
              class="text-primary text-left" title="Subscriptions"
              v-if="hasAccess.viewMultiPatientConsents(viewMultiPatientConsentsRoles)"
              @click="routeTo('PatientConsents')" value="patientConsents">
              <v-tooltip activator="parent">Subscriptions</v-tooltip>
            </v-list-item>
            <v-list-item prepend-icon="mdi-file-sign" :active="consentsRoutes.includes(activeRouteName)"
              class="text-primary text-left" title="Subscriptions" v-if="orgFeature === ORG_CONSENT_FEATURE.ENABLED"
              @click="routeTo('ListMultiPatientOtherConsents')" value="ListMultiPatientOtherConsents">
              <v-tooltip activator="parent">Consents</v-tooltip>
            </v-list-item>
            <v-list-item prepend-icon="mdi-poll" :active="organizationReportsRoutes.includes(activeRouteName)"
              v-if="hasAccess.viewOrgReports(viewOrgReportsRoles)" class="text-primary text-left"
              @click="navigateToOrgReports()" title="Reports" value="orgReports">
              <v-tooltip activator="parent">Reports</v-tooltip>
            </v-list-item>
            <v-list-item prepend-icon="mdi-tune" :active="organizationRoutes.includes(activeRouteName)"
              class="text-primary text-left" v-if="hasAccess.viewOrganization(viewOrgRoles)" @click="openOrgProfile()"
              title="Settings" value="org">
              <v-tooltip activator="parent">Settings</v-tooltip>
            </v-list-item>

          </v-list>
        </slot>
      </div>
      <v-app-bar-nav-icon variant="text" class="mt-1 mb-2 text-primary" @click="toggleDrawer()">
        <v-icon icon="mdi-unfold-more-vertical" />
      </v-app-bar-nav-icon>
    </v-navigation-drawer>
  </div>



</template>

<style scoped>
.left-nav-bar-pos {
  margin-right: 8px;
}

.left-nav-bar-shadow {
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>
